/**
 * QuotePane jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'QuotePane',
        defaults = {
            tweenSpeed: 0.5,
            tweenDelay: 0.5,
            // this prop stores the route state of the app just before the pane opens, used to go back to that route when pane closes
            routeOnClose: "/",
            maxTopCSSPositionWhenOpen: 60, // topBar height
            app: null,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this._opened = false;
        this.$paneHeader = this.$element.find('.pane-header');
        this.$paneBody = this.$element.find('.pane-body');
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            self.resize();
            self.$element.find('form').attr('action', '/devis');
            self.$paneBody.mCustomScrollbar({
                axis: 'y',
            });
        },
        resize: function () {
            var self = this;
            if (self.isOpen()) {
                var top = self._topCSSPositionWhenOpen();
                self.$element.css('top', top + 'px');
                if (top <= self.settings.maxTopCSSPositionWhenOpen) {
                    var vOffset;
                    if (self.settings.app.isResponsiveView()) {
                        vOffset = 76;
                    } else {
                        vOffset = 140;
                    }
                    self.$paneBody.height(windowHeight() - top - vOffset);
                } else {
                    self.$paneBody.height('auto');
                    var top = self._topCSSPositionWhenOpen();
                    self.$element.css('top', top + 'px');
                }
            } else {
                self.$element.css('top', self._topCSSPositionWhenClosed() + 'px');
            }
        },
        setRouteOnClose: function (value) {
            var self = this;
            self.routeOnClose = value;
            self.$element.find('.btn-close').attr('href', value);
        },
        open: function () {
            var self = this;

          // set gclid hidden field value (in GravityForms this field has ID #17)
          $('input#input_2_17').val(readCookie('gclid'));
          $('input#input_4_17').val(readCookie('gclid'));

            if (self.isOpen() === false) {
                self.$element.trigger('quotePaneOpen');
                self.$element.removeClass('hidden').show();
                self._opened = true;
                TweenLite.set(self.$element, {
                    top: windowHeight()
                });
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    top: self._topCSSPositionWhenOpen() + 'px',
                    onComplete: function () {
                        self.resize();
                        self.$element.trigger('quotePaneOpened');
                    }
                });
            }
        },
        close: function () {
            var self = this;
            if (self.isOpen()) {
                self.$element.trigger('quotePaneClose');
                self._opened = false;
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    top: windowHeight(),
                    onComplete: function () {
                        self.$element.hide();
                        self.$element.trigger('quotePaneClosed');
                    }
                });
            }
        },
        toggle: function () {
            return this.isOpen() ? this.close() : this.open();
        },
        isOpen: function () {
            return this._opened;
        },
        _topCSSPositionWhenClosed: function () {
            var self = this;
            return windowHeight() - self.$paneHeader.height();
        },
        _topCSSPositionWhenOpen: function () {
            var self = this;
            return self.settings.maxTopCSSPositionWhenOpen;
        }
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);
