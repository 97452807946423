/**
 URI Routing (with page.js - https://github.com/visionmedia/page.js)
 **/
var Router = (function (app) {

    function Router(app) {

        this.app = app;
        this.enabled = true;
        this.lastRoute = "/";
        this.pageOptions = {
            click: true,
            popstate: true,
            dispatch: true,
            hashbang: false
        };

        this.stop = function () {
            this.enabled = false;
            page.stop();
        };

        this.start = function () {
            this.enabled = true;
            page.start(this.pageOptions);
        };

        page.base('/');

        // on enregistre la valeur de la route précédente à chaque changement de route (utilisé pour revenir à la route précédente dans certains cas : fermeture du volet Contact par exemple)
        page.exit((function (context, next) {
            this.lastRoute = context.pathname;
            next();
        }).bind(this));

        // Route callback pour une page actualité
        page('nos-espaces/les-espaces/*', this.app.espaceRouteCallback.bind(this.app));
        page('our-spaces/spaces/*', this.app.espaceRouteCallback.bind(this.app));

        // Route callback pour chaque section et sous-section de la page
        for (var i in SiteConfig.sections) {
            page(SiteConfig.sections[i].path.substring(1), this.app.sectionRouteCallback.bind(this.app));
            page(SiteConfig.sections[i].path.substring(1) + '/*', this.app.subSectionRouteCallback.bind(this.app));
        }

        // Route callback pour la liste des actualités
        page('actualites', this.app.newsListRouteCallback.bind(this.app));
        page('news', this.app.newsListRouteCallback.bind(this.app));

        // Route callback pour une page actualité
        page('actualites/*', this.app.newsDetailRouteCallback.bind(this.app));
        page('news/*', this.app.newsDetailRouteCallback.bind(this.app));

        // Route callback pour le volet Contact
        page('contact', this.app.contactRouteCallback.bind(this.app));

        // Route callback pour le volet Demande de devis
        page('devis', this.app.quoteRouteCallback.bind(this.app));
        page('quote', this.app.quoteRouteCallback.bind(this.app));

        // Route callback pour le volet Visite 360
        page('visite-360', this.app.visite360RouteCallback.bind(this.app));
        page('360-tour', this.app.visite360RouteCallback.bind(this.app));

        // Route callback pour le volet Newsletter
        //page('newsletter', this.app.newsletterRouteCallback.bind(this.app));

        // Page standard
        page('*', this.app.standardPageRouteCallback.bind(this.app));

        // Router initialization
        page(this.pageOptions);
    }

    return Router;
})();
