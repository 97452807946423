/**
 * NewsPane jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'NewsPane',
        defaults = {
            tweenSpeed: 0.5,
            tweenDelay: 0.5,
            // this prop stores the route state of the app just before the NewsPane opens, used to go back to that route when NewsPane closes
            routeOnClose: "/"
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this._opened = false;
        this._firstLoad = true;
        this._page = 1;
        this._perPage = 5;
        this._allLoaded = false;
        this._entryHBS = $("#news-entry-template").html();
        this._entryTemplate = Handlebars.compile(this._entryHBS);
        this.$list = this.$element.find('.list');
        this.$loader = this.$element.find('.loader');
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            self.$element.hide();
            Handlebars.registerHelper('__featured_list', function (_embedded) {
                if (_embedded['wp:featuredmedia']) {
                    return '<img src="' + _embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url + '" class="featured">';
                }
            });
            Handlebars.registerHelper('__date', function (date) {
                var d = new Date(date);
                //return d.getDate() + ' ' + d.getMonth() + ' ' + d.getFullYear();
                return moment(d).format('LL');
            });
            self.$element.on('newItemsAppended', function (e, firstNewEntry) {
                self.$list.mCustomScrollbar('update');
                self.$list.mCustomScrollbar('scrollTo', firstNewEntry);
            });
            self.resize();
            self.$list.mCustomScrollbar({
                axis: 'y',
                callbacks: {
                    onTotalScroll: function () {
                        self.fetch(self._page + 1);
                    }
                }
            });
            self.$list.on('click', '.entry', function (e) {
                self.$list.find('.entry').removeClass('active');
                $(this).addClass('active');
                self.$element.trigger({
                    type: 'entryClick',
                    id: $(this).attr('data-id'),
                    title: $(this).attr('data-title'),
                    slug: $(this).attr('data-slug')
                });
                //self.settings.newsDetail.$element.show();
                //self.settings.newsDetail.loadItem($(this).attr('data-id'));
            });
        },
        resize: function () {
            this.$element.height(windowHeight() - 60);
            if (this.isOpen() === false) {
                this.$element.css('left', '-' + this.$element.width() + 'px');
            }
            var listHeight = this.$element.height() - this.$list.parent().css('padding-top').replace(/[^-\d.]/g, '') * 2;
            this.$list.height(listHeight);
        },
        setRouteOnClose: function (value) {
            var self = this;
            self.routeOnClose = value;
            self.$element.find('.btn-close').attr('href', value);
        },
        open: function () {
            var self = this;
            if (self.isOpen() === false) {
                self.$element.show();
                self.$element.trigger('newsPaneOpen');
                self._opened = true;
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    left: '0px',
                    delay: self.settings.tweenDelay,
                    onComplete: function () {
                        self.$element.trigger('newsPaneOpened');
                    }
                });
                if (self._firstLoad) {
                    // on vide la liste des posts pré-rempli par le serveur (pour le SEO)
                    self.$list.find('.mCSB_container').empty();
                    // on récupère la première page de posts
                    self.fetch(1);
                } else {
                    self.$list.mCustomScrollbar('scrollTo', 0);
                }
            }
        },
        close: function () {
            var self = this;
            if (self.isOpen()) {

                function _close() {
                    self.$element.trigger('newsPaneClose');
                    self._opened = false;
                    TweenLite.to(self.$element, self.settings.tweenSpeed, {
                        left: '-' + self.$element.width() + 'px',
                        onComplete: function () {
                            self.$element.hide();
                            self.$element.trigger('newsPaneClosed');
                        }
                    });
                }

                if (self.settings.newsDetail.isOpen()) {
                    self.settings.newsDetail.$element.on('newsDetailClosed', function () {
                        self.settings.newsDetail.$element.off('newsDetailClosed');
                        _close();
                    });
                    self.settings.newsDetail.close();
                } else {
                    _close();
                }
            }
        },
        toggle: function () {
            return this.isOpen() ? this.close() : this.open();
        },
        isOpen: function () {
            return this._opened;
        },
        fetch: function (page) {
            var self = this;
            if (self._allLoaded === false) {
                TweenLite.set(self.$loader, {
                    autoAlpha: 1
                });
                self.$loader.removeClass('hidden');
                TweenLite.to(self.$loader, .5, {
                    autoAlpha: 1
                });
                $.getJSON(SiteConfig.home_url + '/wp-json/wp/v2/posts', {
                    page: page || this._page,
                    per_page: this._perPage,
                    _embed: true
                }, function (data) {
                    if (data.length > 0) {
                        var firstNewEntry;
                        //for (var k = 0; k < 2; k++) { // DEBUG only
                        for (var i in data) {
                            var item = data[i];
                            var $entry = $(self._entryTemplate(item));
                            self.$list.find('.mCSB_container').append($entry);
                            if (firstNewEntry === undefined) {
                                firstNewEntry = $entry;
                            }
                        }
                        //}
                        self.$element.trigger('newItemsAppended', firstNewEntry);
                        self._firstLoad = false;
                    } else {
                        self._allLoaded = true;
                        self.$element.find('.no-more').removeClass('hidden').show(500).delay(2000).hide(500);
                    }
                    TweenLite.to(self.$loader, .5, {
                        autoAlpha: 0
                    });
                });
            }
        }
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);
