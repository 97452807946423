/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var App = {
    // All pages
    'common': {
      // JavaScript to be fired on all pages
      init: function () {

        if (document.body.classList.contains('formulaire-de-contact') || document.body.classList.contains('contact-form')) {
          // Custom static page for the contact form: we do not want all the JS stuff.
          return;
        }

        function handleOrientation() {
          var orientation = screen.orientation || screen.mozOrientation || screen.msOrientation || window.orientation;
          //log(orientation);
          if (orientation && (
              (typeof orientation === 'object' && orientation.type.match("landscape")) // chrome | Firefox
              ||
              (typeof orientation === 'string' && orientation.match("landscape")) // IE
              ||
              (typeof orientation === 'number' && orientation != 0) // Safari
            )
            && screen.availWidth < 768) {
            $('#alert-screen-orientation').show();
          } else {
            $('#alert-screen-orientation').hide();
          }
        };
        window.addEventListener("orientationchange", handleOrientation, false);
        handleOrientation();

        moment.locale(SiteConfig.current_language);

        var app = this;

        app.trackPageView = function (pathname) {
          // from https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
          gtag('event', 'page_view', {
            page_title: document.title,
            page_location: window.location.href,
            page_path: window.location.pathname
          });
        };

        app.responsiveWidth = 768;
        app.isResponsiveView = function () {
          return $(window).width() < app.responsiveWidth;
        };

        app.md = new MobileDetect(window.navigator.userAgent);

        app.subSectionOpenDirection = 'left';

        /**
         * Helper function to add/remove the .no-scroll class on the <html> element (example : when ContactPane or QuotePane is open)
         * The .no-scroll class should only be added on mobile !
         */

        app._touchMoveHandler = function (e) {
          e.preventDefault();
        };
        app.disableTouchMove = function () {
          logEvent('disableTouchMove');
          //$('#main').css('top', '-' + $('body').scrollTop() + 'px');
          //$('html').addClass('no-scroll');
          document.getElementById('main').addEventListener('touchmove', app._touchMoveHandler, false);
        };
        app.enableTouchMove = function () {
          logEvent('enableTouchMove');
          //$('html').removeClass('no-scroll');
          //$('body').scrollTop(Math.abs($('#main').css('top').replace(/[^-\d\.]/g, '')));
          //$('#main').css('top', 'auto');
          document.getElementById('main').removeEventListener('touchmove', app._touchMoveHandler, false);
        };

        // #btn-share live event
        $(document).on('click', '#btn-open-share-overlay', function (e) {
          e.preventDefault();
          $('#share-overlay').removeClass('hidden').fadeIn(250);
          $('#share-overlay a.btn-share').each(function () {
            var $this = $(this);
            var href = $this.attr('data-href');
            href = href.replace('{url}', encodeURIComponent(document.location.href));
            href = href.replace('{tweet_text}', encodeURIComponent(document.title));
            href = href.replace('{mail_subject}', encodeURIComponent(document.title));
            href = href.replace('{mail_body}', encodeURIComponent(document.location.href));
            href = href.replace('{title}', encodeURIComponent(document.title));
            $this.attr('href', href);
          });
        });
        $('#share-overlay .btn-close-share').on('click', function (e) {
          e.preventDefault();
          $('#share-overlay').fadeOut(250);
        });

        // btn close modal info recevoir
        $('#info-recevoir-overlay').on('click', function (e) {
          if ($(e.target).is($('a[data-action=goToRecevoir]'))) {
            e.stopImmediatePropagation();
            e.stopPropagation();
          } else {
            $('#info-recevoir-overlay').fadeOut();
          }
        });
        $('#info-recevoir-overlay a[data-action=close]').on('click', function (e) {
          e.preventDefault();
          $('#info-recevoir-overlay').fadeOut();
        });

        // HAMBURGER + SLIDEOUT MENU
        // burger button to toggle offcanvas menu
        $('#top-bar .burger').SimpleToggle({
          toggleOnClassName: 'is-open',
          toggleOffClassName: 'is-closed'
        });
        // offcanvas menu
        app.slideout = new Slideout({
          panel: document.getElementById('main'),
          menu: document.getElementById('offcanvas-menu'),
          padding: 260,
          touch: false,
          tolerance: 70,
          duration: 300,
          fx: 'ease',
        });
        app.slideout.closeHandlerEnabled = true;
        app.slideout
          .on('beforeopen', function () {
            logEvent('slideout beforeopen');
            $('#top-bar').addClass('toggle');
            app.$contactPane.ContactPane('close');
            app.$quotePane.QuotePane('close');
            if (app.isResponsiveView()) {
              // sur mobile, on masque l'onglet du ContactPane lorsque le menu est ouvert
              app.$contactPane.hide();
              app.disableTouchMove();
            }
            // la classe menu-open n'est utile que pour gérer l'affichage CSS de l'admin bar de WP
            $('body').addClass('menu-open');
            // désactivation du fullpage
            $.fn.fullpage.setAllowScrolling(false, 'all');
            $.fn.fullpage.setKeyboardScrolling(false, 'all');
          })
          .on('beforeclose', function () {
            logEvent('slideout beforeclose');
            $('#top-bar').removeClass('toggle');
          })
          .on('close', function () {
            logEvent('slideout close');
            // rappel : la classe menu-open n'est utile que pour gérer l'affichage CSS de l'admin bar de WP
            $('body').removeClass('menu-open');

            if (app.isResponsiveView()) {
              app.$contactPane.show();
              if (!app.$newsPane.data('plugin_NewsPane').isOpen()) {
                app.enableTouchMove();
              }
            }
            if (this.closeHandlerEnabled) {
              // réactivation du fullpage, sauf si closeHandlerEnabled a été défini à false
              $.fn.fullpage.setAllowScrolling(true, 'all');
              $.fn.fullpage.setKeyboardScrolling(true, 'all');
            }
          });
        $('#top-bar .burger').on('click', function () {
          //log('burger click');
          app.slideout.toggle();
        });

        $('.sections-menu').find('.sub-menu > li > a').on('click', function (e) {
          //log('menu sub-menu a.click');
          e.preventDefault();
          var $this = $(this);
          // On intercepte le click sur les éléments de menu correspondants à des sous-sections
          // pour définir la direction de l'animation d'ouverture de la sous-section.
          // Si il y a déjà un élément active dans ce sous-menu, ça signifie que la page en cours est déjà une sous-section appartenant à ce sous-menu
          // En fonction de l'index de l'élément cliqué, on sait si la sous-section précède ou suit la sous-section en cours, et on peut donc définir la direction de l'anmation.
          var $activeSibling = $this.closest('.sub-menu').find(' > li.active');
          if ($activeSibling) {
            if ($activeSibling.index() < $this.parent().index()) {
              app.subSectionOpenDirection = 'right';
            } else {
              app.subSectionOpenDirection = 'left';
            }
          } else {
            app.subSectionOpenDirection = 'left';
          }
          page($this.attr('href'));
        });

        // #main
        app.$main = $('#main');

        // OFFCANVAS ANCHORS (right side)
        app.$anchors = $('#anchors');
        if (app.md.mobile()) {
          app.$anchors.Anchors({enabled: false});
        } else {
          app.$anchors.Anchors();
        }

        // CONTACT PANE
        app.$contactPane = $('#contact-pane');
        app.$contactPane.ContactPane({
          app: app,
        });
        app.$contactPane.on('contactPaneOpen', function () {
          logEvent('contactPaneOpen');
          var routeOnClose = "/";
          if (app.router) {
            routeOnClose = app.router.lastRoute;
          }
          app.$contactPane.ContactPane('setRouteOnClose', routeOnClose);
        });
        app.$contactPane.on('contactPaneOpened', function () {
          logEvent('contactPaneOpened');
          if (app.isResponsiveView()) {
            app.disableTouchMove();
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        });
        app.$contactPane.on('contactPaneClose', function () {
          logEvent('contactPaneClose');
          if (!app.$newsPane.data('plugin_NewsPane').isOpen()) {
            app.enableTouchMove();
            $.fn.fullpage.setAllowScrolling(true, 'all');
            $.fn.fullpage.setKeyboardScrolling(true, 'all');
          }
        });

        // QUOTE PANE
        app.$quotePane = $('#quote-pane');
        app.$quotePane.QuotePane({app: app});
        app.$quotePane.on('quotePaneOpen', function () {
          logEvent('quotePaneOpen');
          var routeOnClose = app.$contactPane.data('routeOnClose') || "/";
          app.$quotePane.QuotePane('setRouteOnClose', routeOnClose);
        });
        app.$quotePane.on('quotePaneOpened', function () {
          logEvent('quotePaneOpened');
          if (app.isResponsiveView()) {
            app.disableTouchMove()
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        });
        app.$quotePane.on('quotePaneClose', function () {
          logEvent('quotePaneClose');
          app.enableTouchMove();
          $.fn.fullpage.setAllowScrolling(true, 'all');
          $.fn.fullpage.setKeyboardScrolling(true, 'all');
        });

        // NEWS DETAIL
        app.$newsDetail = $('#news-detail');
        app.$newsDetail.NewsDetail();
        $('body').on('newsDetailOpen', function () {
          logEvent('newsDetailOpen');
          app.$newsPane.addClass('no-shadow');
          app.$newsDetail.removeClass('hidden').show();
        });
        app.$newsDetail.on('newsDetailClosed', function () {
          logEvent('newsDetailClosed');
          app.$newsPane.removeClass('no-shadow');
        });

        // NEWS PANE
        app.$newsPane = $('#news-pane');
        app.$newsPane.NewsPane({
          newsDetail: app.$newsDetail.data('plugin_NewsDetail')
        });
        app.$newsPane.on('entryClick', function (e) {
          updateMeta(e.title);
          page('/' + (SiteConfig.current_language == 'fr' ? 'actualites' : 'news') + '/' + e.slug);
        });
        // Actions sur le FullPage lors de l'ouverture et de la fermeture des News
        app.$newsPane.on('newsPaneOpen', function () {
          logEvent('newsPaneOpen');
          var routeOnClose = "/";
          if (app.router) {
            routeOnClose = app.router.lastRoute;
          }
          app.$newsPane.NewsPane('setRouteOnClose', routeOnClose);
        });
        app.$newsPane.on('newsPaneOpened', function () {
          logEvent('newsPaneOpened');
          if (app.isResponsiveView()) {
            app.disableTouchMove();
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        });
        app.$newsPane.on('newsPaneClosed', function () {
          logEvent('newsPaneClosed');
          app.enableTouchMove();
          $.fn.fullpage.setAllowScrolling(true, 'all');
          $.fn.fullpage.setKeyboardScrolling(true, 'all');
        });

        // FICHE ESPACE
        app.$espaceDetail = $('#espace-detail');
        app.$espaceDetail.Espace({app: app});
        app.$espaceDetail.on('espaceDetailOpen', function () {
          logEvent('espaceDetailOpen');
        });
        app.$espaceDetail.on('espaceDetailOpened', function () {
          logEvent('espaceDetailOpened');
          if (app.isResponsiveView()) {
            app.disableTouchMove()
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        });
        app.$espaceDetail.on('espaceDetailClose', function () {
          logEvent('espaceDetailClose');
          $('body').removeClass('espace');
          if (!app.md.mobile()) {
            app.$anchors.show();
          }
          app.enableTouchMove();
          $.fn.fullpage.setAllowScrolling(true, 'all');
          $.fn.fullpage.setKeyboardScrolling(true, 'all');
        });
        app.$espaceDetail.on('espaceDetailClosed', function () {
          logEvent('espaceDetailClosed');
        });

        // VISITE 360
        app.$visite360Pane = $('#visite-360');
        app.$visite360Pane.Visite360Pane({app: app});
        app.$visite360Pane.on('visite360PaneOpen', function () {
          logEvent('visite360PaneOpen');
          var routeOnClose = "/";
          if (app.router) {
            routeOnClose = app.router.lastRoute;
          }
          app.$visite360Pane.Visite360Pane('setRouteOnClose', routeOnClose);
        });
        app.$visite360Pane.on('visite360PaneOpened', function () {
          logEvent('visite360PaneOpened');
          if (app.isResponsiveView()) {
            app.disableTouchMove()
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        });
        app.$visite360Pane.on('visite360PaneClose', function () {
          logEvent('visite360PaneClose');
          if (!app.md.mobile()) {
            app.$anchors.show();
          }
          app.enableTouchMove();
          $.fn.fullpage.setAllowScrolling(true, 'all');
          $.fn.fullpage.setKeyboardScrolling(true, 'all');
        });

        // NEWSLETTER
        app.$newsletter = $('#newsletter');
        app.$newsletter.Newsletter({app: app});
        app.$newsletter.on('newsletterOpen', function () {
          logEvent('newsletterOpen');
          var routeOnClose = "/";
          if (app.router) {
            routeOnClose = app.router.lastRoute;
          }
          app.$newsletter.Newsletter('setRouteOnClose', routeOnClose);
        });
        app.$newsletter.on('newsletterOpened', function () {
          logEvent('newsletterOpened');
          if (app.isResponsiveView()) {
            app.disableTouchMove()
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        });
        app.$newsletter.on('newsletterClose', function () {
          logEvent('newsletterClose');
          if (!app.md.mobile()) {
            app.$anchors.show();
          }
          app.enableTouchMove();
          $.fn.fullpage.setAllowScrolling(true, 'all');
          $.fn.fullpage.setKeyboardScrolling(true, 'all');
        });

        // Standard Page
        app.$stdPage = $('#std-page');
        app.$stdPage.StdPage({app: app});
        app.$stdPage.on('stdPageOpen', function () {
          logEvent('stdPageOpen');
          var routeOnClose = app.$stdPage.data('routeOnClose') || "/";
          app.$stdPage.StdPage('setRouteOnClose', routeOnClose);
        });
        app.$stdPage.on('stdPageOpened', function () {
          logEvent('stdPageOpened');
          if (app.isResponsiveView()) {
            app.disableTouchMove()
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        });
        app.$stdPage.on('stdPageClose', function () {
          logEvent('stdPageClose');
          app.enableTouchMove();
          $.fn.fullpage.setAllowScrolling(true, 'all');
          $.fn.fullpage.setKeyboardScrolling(true, 'all');
        });

        /**
         * Récupère le contenu d'une section via AJAX
         * @param fpIndex index dans le FullPage (de 1 à X)
         */
        app.fetchSectionContent = function (fpIndex, byPassPathCheck) {
          byPassPathCheck = byPassPathCheck || false;
          // Chargement Ajax de la section
          var $section = $('.fp-section:eq(' + (fpIndex - 1) + ')');
          if (!$section.hasClass('loaded')) {
            var sectionPath = $section.attr('data-path');
            if ((page.current != "" && sectionPath == "/" + page.current.replace(/^\//, "")) || byPassPathCheck) {
              // permet d'éviter le double load au chargement du site si l'URL correspond à une section (dans ce cas la section est chargée en server-side)
              // permet aussi déviter de charger le contenu de la section si l'URL est celle d'une sous-section
              var sectionId = $section.attr('data-id');
              $section.addClass('loading');
              $.getJSON(SiteConfig.home_url + '/wp-json/wp/v2/pages/' + sectionId, function (item) {
                logEvent('fetchSectionContent getJSON complete');
                $section.removeClass('loading');
                $section.html(item.section_content);
                $section.addClass('loaded');
                app.initSectionAfterFetchContent($section); // include implicit call to app.sectionReady
                app.fullpageOnLeaveEnabled = true;
              });
            }
          } else {
            app.sectionReady($section);
          }
        };

        /**
         * Récupère le contenu d'une sous-section via Ajax
         * @param $subSection l'élément DOM correspondant à la sous-section
         */
        app.fetchSubSectionContent = function ($subSection) {
          if (!$subSection.hasClass('loaded')) {
            var subSectionId = $subSection.attr('data-id');
            $.getJSON(SiteConfig.home_url + '/wp-json/wp/v2/pages/' + subSectionId, function (item) {
              logEvent('fetchSubSectionContent getJSON complete');
              $subSection.html(item.section_content);
              app.$subSections.SubSections('resize');
              $subSection.addClass('loaded');
              app.initSubSectionAfterFetchContent($subSection);
            });
          } else {
            app.$subSections.SubSections('resize');
            //app.subSectionReady($subSection);
          }
        };

        // SUB-SECTIONS
        app.$subSections = $('#sub-sections');
        app.$subSections.SubSections({
          app: app
        });
        app.$subSections.on('subSectionOpen', function (e, data) {
          logEvent('subSectionOpen');
          app.fetchSubSectionContent(data.$target);
        });
        app.$subSections.on('subSectionOpened', function (e, data) {
          logEvent('subSectionOpened');
          if (app.isResponsiveView()) {
            app.disableTouchMove();
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        });
        app.$subSections.on('subSectionClose', function () {
          logEvent('subSectionClose');
          $('body').removeClass('subSection');
        });
        app.$subSections.on('subSectionClosed', function () {
          logEvent('subSectionClosed');
          if (app.isResponsiveView()) {
            app.enableTouchMove();
          }
          $.fn.fullpage.setAllowScrolling(true, 'all');
          $.fn.fullpage.setKeyboardScrolling(true, 'all');
          app.subSectionOpenDirection = 'left';
        });

        // WINDOW RESIZE EVENT
        $(window).smartResize(function (e) {
          app.updateScrollWrapInSections();
          app.$subSections.SubSections('resize');
          app.$newsPane.NewsPane('resize');
          app.$newsDetail.NewsDetail('resize');
          app.$contactPane.ContactPane('resize');
          app.$quotePane.QuotePane('resize');
          app.$visite360Pane.Visite360Pane('resize');
          app.$newsletter.Newsletter('resize');
          app.$stdPage.StdPage('resize');
        });

        // INIT fullpage.js
        // le flag suivant est sur false par défaut pour éviter un bug lorsque le site mobile est appelé avec une URL de sous-section ou d'esapce
        app.fullpageOnLeaveEnabled = false;
        app.$fullpage = $('#fullpage');
        app.$fullpage.fullpage({
          //Navigation
          //anchors: _.pluck(SiteConfig.sections, 'path'),
          //lockAnchors: true,

          //navigation: false,
          //navigationPosition: 'right',
          //navigationTooltips: ['firstSlide', 'secondSlide'],
          //showActiveTooltip: false,
          //slidesNavigation: true,
          //slidesNavPosition: 'bottom',

          //Scrolling
          //css3: true,
          //scrollingSpeed: 700,
          //autoScrolling: true,
          //fitToSection: false,
          //fitToSectionDelay: 1000,
          //scrollBar: false,
          //easing: 'easeInOutCubic',
          //easingcss3: 'ease',
          //loopBottom: false,
          //loopTop: false,
          //loopHorizontal: true,
          //continuousVertical: false,
          normalScrollElements: '.scroll-wrap', // ne concerne pas les éléments sur lesquels la mCustomScrollbar n'est pas activée
          scrollOverflow: false,
          //touchSensitivity: 15,
          //normalScrollElementTouchThreshold: 5,

          //Accessibility
          //keyboardScrolling: true,
          animateAnchor: true,
          recordHistory: false,

          //Design
          //controlArrows: true,
          verticalCentered: false,
          //resize: false,
          ////sectionsColor: ['#fff', '#fff'],
          paddingTop: '60px',
          //paddingBottom: '10px',
          //fixedElements: '#header, .footer',
          responsiveWidth: app.responsiveWidth - 1,
          //responsiveHeight: 0,

          //Custom selectors
          //sectionSelector: '.section',
          //slideSelector: '.slide',

          //events
          onLeave: function (index, nextIndex, direction) {
            logEvent('fullpage onLeave');
            var $nextSection = $('.fp-section').eq(nextIndex - 1);
            var path = $nextSection.attr('data-path');
            if (app.fullpageOnLeaveEnabled) {
              logEvent('fullpage onLeave fullpageOnLeaveEnabled');
              // on met en pause les backgroundSliders de la section qu'on quitte
              $('.fp-section').eq(index - 1).find('.full-background-slider').BackgroundSlider('pause');
              // on relance les backgroundSliders de la section sur laquelle on arrive
              $nextSection.find('.full-background-slider').BackgroundSlider('resume');

              if (app.isResponsiveView()
                && !app.slideout.isOpen()
                && !app.$contactPane.data('plugin_ContactPane').isOpen()
                && !app.$quotePane.data('plugin_QuotePane').isOpen()
                && !app.$visite360Pane.data('plugin_Visite360Pane').isOpen()
                && !app.$newsletter.data('plugin_Newsletter').isOpen()
                && !app.$newsPane.data('plugin_NewsPane').isOpen()
                && !app.$newsDetail.data('plugin_NewsDetail').isOpen()
                && app.router && app.router.enabled
              ) {
                // app.isResponsiveView() : sur mobile (< 768), le fullpage est en normalScroll : donc on change la route lorsqu'on change de section, pour déclencher le chargement du contenu
                // !app.*.data('plugin_*').isOpen() : sauf si le slideout/contact/quote/news est ouvert. Car dans ce cas, la position du #main est fixed avec un top calculé, ce qui provoque un event onLeave sur le fullpage.
                // sur desktop/tablet ça se passe dans afterLoad

                page(path);
                /*if ($nextSection.hasClass('loading') == false) {
                 //// J'ai commenté ce bloc car le fetchSectionContent se fait dans le sectionRouteCallback
                 app.fetchSectionContent(nextIndex);
                 }*/
              }
            }
          },
          afterLoad: function (anchorLink, index) {
            logEvent('fullpage afterLoad');
            //if (!app.isResponsiveView()) {
            // sur desktop/tablet (>= 768), le fullpage est en autoScroll : le chargement du contenu est déclenché lors de l'afterLoad
            // sur mobile, ça se passe dans onLeave
            /*var $section = $('.fp-section').eq(index - 1);
             if ($section.hasClass('loading') == false) {
             //// J'ai commenté ce bloc car le fetchSectionContent se fait dans le sectionRouteCallback
             app.fetchSectionContent(index);
             }*/
            //}
            if (app.md.tablet()) {
              if (index == 1) {
                // sur tablet, sur la section d'accueil, on affiche les anchors
                app.$anchors.show();
                //app.$anchors.Anchors('open');
                //app.$anchors.Anchors('updatePosition');
              } else {
                // sur tablet, sur les autres sections on masque anchors
                //app.$anchors.Anchors('close');
                app.$anchors.hide();
              }
            }
          },
          afterRender: function (section) {
            // mise à jour de la position du menu anchors
            app.$anchors.Anchors('updatePosition');
          },
          afterResize: function () {
            // mise à jour de la position du menu anchors
            app.$anchors.Anchors('updatePosition');
          },
        });

        function fullpagePrevSection() {
          var $activeSection = $('.fp-section.active');
          var activeSectionIndex = $activeSection.index();
          var $prev = $activeSection.prev('.fp-section');
          if ($prev.length) {
            page(SiteConfig.sections[activeSectionIndex - 1].path);
          }
        }

        function fullpageNextSection() {
          var $activeSection = $('.fp-section.active');
          var activeSectionIndex = $activeSection.index();
          var $next = $activeSection.next('.fp-section');
          if ($next.length) {
            page(SiteConfig.sections[activeSectionIndex + 1].path);
          }
        }

        // normal scroll elements in fullpage
        $(document).on('fullPageNormalScrollElementEnter', function (e, data) {
          logEvent('fullPageNormalScrollElementEnter');
          // lorsque la souris entre sur un élément ciblé par le FullPage.normalScrollElements, on désactive le fullpage scroll
          if ($(data.element).hasClass('mCS_no_scrollbar') === false) {
            $.fn.fullpage.setAllowScrolling(false, 'all');
          }
        });
        $(document).on('fullPageNormalScrollElementLeave', function (e, data) {
          logEvent('fullPageNormalScrollElementLeave');
          // lorsque la souris quitte un élément ciblé par le FullPage.normalScrollElements, on désactive le fullpage scroll
          // seulement si on est pas dans une sous-section ! (car dans les sous-sections, le fullpageScroll est de toute façon désactivé)
          if ($(data.element).closest('.section').length > 0) {
            $.fn.fullpage.setAllowScrolling(true, 'all');
          }
        });

        // move section up/down mousewheel and next/prev page keypress
        $(document).on('fullPageKeydown fullPageMouseWheelScroll fullPageTouchMove', function (e, data) {
          logEvent('fullPageKeydown fullPageMouseWheelScroll fullPageTouchMove');
          switch (data.code) {
            case 'up':
              fullpagePrevSection();
              break;
            case 'down':
              fullpageNextSection();
              break;
          }
        });
        // move section up/down navigation
        $('.nav-sections .nav-section-prev').on('click', function (e) {
          e.preventDefault();
          fullpagePrevSection();
        });
        $('.nav-sections .nav-section-next').on('click', function (e) {
          e.preventDefault();
          fullpageNextSection();
        });

        app.closeSlideout = function (_closeHandlerEnabled) {
          this.slideout.closeHandlerEnabled = _closeHandlerEnabled;
          if (this.slideout.isOpen()) {
            // on ferme le menu
            this.slideout.close();
            // toggle burger
            $('#top-bar .burger').SimpleToggle('toggle');
          }
        };

        app.slideoutMousemoveHandler = function (e) {
          //log('slideoutMousemoveHandler');
          if (e.pageX > $('.slideout-menu').width()) {
            app.closeSlideout(true);
            $(document).off('mousemove', app.slideoutMousemoveHandler);
          }
        };

        /**
         * Fonction appelée après le chargement Ajax du contenu d'une section,
         * et aussi au chargement de la section initiale au lancement du site
         * @param $section
         */
        app.initSectionAfterFetchContent = function ($section) {
          TweenLite.set($section, {
            autoAlpha: 0
          });
          if ($section.find('.section-content .toggle-intro-detail').length == 0) {
            TweenLite.to($section, 1, {
              autoAlpha: 1,
            });
          } else {
            if (app.md.mobile()) {
              $section.find('.full-background-slider').BackgroundSlider({
                app: app
              });
              TweenLite.to($section, 1, {
                autoAlpha: 1,
              });
            } else {
              TweenLite.set($section.find('.section-content .toggle-intro-detail .intro'), {
                autoAlpha: 0
              });
              var targets = [$section.find('.section-content .gros-titre')];
              if ($section.find('.section-content .img-mot-cle').length > 0) {
                targets.push($section.find('.section-content .img-mot-cle'));
              }
              TweenLite.set(targets, {
                autoAlpha: 0
              });
              // activation du background slider
              $section.find('.full-background-slider').BackgroundSlider({
                app: app
              });

              function onBackgroundSliderFirstImageLoad(e) {
                //log('onBackgroundSliderFirstImageLoad');
                // on désactive cet écouteur d'événement car on veut juste qu'il s'éxécute après le chargement de la première image du slider
                $section.find('.full-background-slider').off('imageLoaded', onBackgroundSliderFirstImageLoad);
                // activation du toggle-intro-detail
                $section.find('.toggle-intro-detail').ToggleIntroDetail({
                  app: app
                });
                // fade tween only when content is fetched from the server (transition with the loader)
                TweenLite.to($section, 1, {
                  autoAlpha: 1,
                  onComplete: function () {
                    // fade retardé sur les éléments de texte pour laisser la photo background visible quelques instants
                    var _contentFadeDuration = 1;
                    TweenLite.to($section.find('.section-content .toggle-intro-detail .intro'), _contentFadeDuration, {
                      autoAlpha: 1,
                    });
                    TweenLite.to(targets, _contentFadeDuration / 2, {
                      autoAlpha: 1,
                      delay: .5,
                    });
                  }
                });
              }
            }
          }
          if ($section.find('.full-background-slider').length > 0) {
            $section.find('.full-background-slider').on('imageLoaded', onBackgroundSliderFirstImageLoad);
          } else if ($section.find('.full-background-single').length > 0) {
            $('<img/>').attr('src', $section.find('.full-background-single').attr('data-background-image')).load(function () {
              $(this).remove();
              $section.find('.full-background-single').css('backgroundImage', 'url(' + $section.find('.full-background-single').attr('data-background-image') + ')');
              // activation du toggle-intro-detail
              $section.find('.toggle-intro-detail').ToggleIntroDetail({
                app: app
              });
              // fade tween only when content is fetched from the server (transition with the loader)
              TweenLite.to($section, 1, {
                autoAlpha: 1,
                onComplete: function () {
                  // fade retardé sur les éléments de texte pour laisser la photo background visible quelques instants
                  var _contentFadeDuration = 1;
                  TweenLite.to($section.find('.section-content .toggle-intro-detail .intro'), _contentFadeDuration, {
                    autoAlpha: 1,
                  });
                  TweenLite.to(targets, _contentFadeDuration / 2, {
                    autoAlpha: 1,
                    delay: .5,
                  });
                }
              });
            });
          }
          // sur mobile, on supprime la valeur css height fixée par le fullpage (sauf sur la home)
          if (app.isResponsiveView()) {
            if ($section.attr('data-path') != "/") {
              $section.css('height', 'auto');
            }
          }
          //
          app.sectionReady($section);
        };
        app.sectionReady = function ($section) {
          if (app.isResponsiveView()) {
            $('body').scrollTop($section.position().top);
          } else {
            $section.find('.bloc > a > img').on('load', function (e) {
              app.updateScrollWrapInSections();
            });
            app.updateScrollWrapInSections();
            $section.find('.scroll-wrap').mCustomScrollbar({
              axis: 'y'
            });
          }
        };
        app.updateScrollWrapInSections = function () {
          var $scrollWrapsInSections = $('.fp-section > .section-content > .scroll-wrap');
          $scrollWrapsInSections.each(function () {
            var $this = $(this);
            //log(windowHeight(), $this.position().top);
            $this.height(
              // 60 = top bar height
              // 40 = btn contact height
              // 30 = margin-top of container
              windowHeight() - $this.position().top - (60 + 40 + 30)
            );
            $this.mCustomScrollbar('update');
          });
        };
        if ($('.section.loaded').length > 0) {
          app.initSectionAfterFetchContent($('.section.loaded'));
        }

        /**
         * Fonction appelée après le chargement Ajax du contenu d'une sous-section, et aussi au chargement de la sous-section initiale au lancement du site
         * @param $section
         */
        /*app.subSectionReady = function ($subSection) {
         // le Tween alpha est fait sur le $subSection.find('.section-content') car on veut garder la background opaque qui est sur $subSection
         TweenLite.set($subSection.find('.section-content'), {
         autoAlpha: 0
         });
         TweenLite.set($subSection.find('.section-content .content'), {
         autoAlpha: 0
         });
         TweenLite.to($subSection.find('.section-content'), 1, {
         autoAlpha: 1,
         onComplete: function () {
         TweenLite.to($subSection.find('.section-content .content'), 1, {
         autoAlpha: 1,
         });
         }
         });
         };*/
        app.initSubSectionAfterFetchContent = function ($subSection) {
          $subSection.find('.prev-sub-section').on('click', function (e) {
            e.preventDefault();
            app.subSectionOpenDirection = 'left';
            var href = $(this).attr('href');
            page(href);
          });
          $subSection.find('.next-sub-section').on('click', function (e) {
            e.preventDefault();
            app.subSectionOpenDirection = 'right';
            var href = $(this).attr('href');
            page(href);
          });
          app.$subSections.SubSections('initActiveContent');
          //app.subSectionReady($subSection);
        };
        // init de la sub-section chargée par défaut au chargement du site dans le cas où l'URL est celle d'une sub-section
        app.initSubSectionAfterFetchContent($('.sub-section.loaded'));

        // INIT APP ROUTER
        app.sectionRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          if (app.router) {
            if (!app.router.enabled) {
              return;
            }
            if (app.router.lastRoute == context.pathname) {
              // si le path demandé est le même que l'actuel,
              // on ferme le menu et on s'arrête là
              app.closeSlideout();
              return;
            }
          }

          logEvent('sectionRouteCallback', context.pathname);

          // on récupère l'index de la section active courante
          var currentSectionIndex = $('.fp-section.active').index();

          // on récupère l'index de la section correspondant à l'URL demandée
          var activeSection = _.find(SiteConfig.sections, function (o) {
            if (o.path === context.pathname) {
              return o;
            }
          });
          var activeSectionIndex = SiteConfig.sections.indexOf(activeSection);
          var $activeSection = $('.fp-section').eq(activeSectionIndex);

          if (activeSectionIndex != currentSectionIndex) {
            // si le nouvel index est différent de l'index en cours
            app.fullpageOnLeaveEnabled = true;
            if (app.isResponsiveView()) {
              // on positionne le body sur mobile au niveau de la section
              $('body').scrollTop($activeSection.position().top);
            } else {
              // on actionne le fullpage pour aller à la section
              $.fn.fullpage.moveTo(activeSectionIndex + 1);
            }
          } else if (currentSectionIndex === 0 && activeSectionIndex === currentSectionIndex) {
            // dans ce cas où les index sont équivalent et égaux à zéro, ça signifie que l'URl demandée est l'URL racine (page d'accueil)
            // dans ce cas on autorise le fullpageOnLeave pour permettre le chargemen des sections au scroll dans la page (ne concerne que le mobile)
            app.fullpageOnLeaveEnabled = true;
          }
          // sinon on vérifie si le contenu de la section en cours est déjà chargé (classe .loaded)
          // cela peut arriver lorsqu'on arrive sur le site via une URL de sous-section : dans ce cas le fullpage est positionné sur la section parent, mais son contenu n'a pas été chargé.
          if (!$('.fp-section:eq(' + activeSectionIndex + ')').hasClass('loaded')) {
            // on fait + 1 car la fonction attend un index en base 1 (fullPage)
            app.fetchSectionContent(activeSectionIndex + 1);
          }

          // add active class on sections menu item
          $('.sections-menu > li').removeClass('active');
          $('.sections-menu').find('> li > a[href="' + context.pathname + '"]').parent().addClass('active');

          // fermeture du menu, sous certaines conditions !
          if (app.isResponsiveView()) {
            // on ferme le menu
            app.closeSlideout(true);
          } else {
            var $slideoutLiActive = $('.slideout-menu .sections-menu > li.active');
            if ($slideoutLiActive.hasClass('menu-item-has-children')) {
              if (app.slideout.isOpen()) {
                // si la section a des sous-sections, on veut laisser le menu ouvert pour montrer qu'il y a des sous-sections.
                // mais si le curseur quitte la zone du menu, on va le fermer
                $(document).on('mousemove', app.slideoutMousemoveHandler);
              }
            } else {
              // si la section n'a pas de sous-sections, on ferme le menu
              app.closeSlideout(true);
              $(document).off('mousemove', app.slideoutMousemoveHandler);
            }
          }

          // add active-1, active-2, etc. sur les siblings du li.active dans le #anchors
          this.$anchors.Anchors('updateActiveStyle');
          if (activeSectionIndex == 0) {
            this.$anchors.Anchors('disableToggle');
            this.$anchors.Anchors('open');
          } else {
            this.$anchors.Anchors('enableToggle');
          }

          // update page meta
          updateMeta(SiteConfig.sections[activeSectionIndex].title);
          $('body').attr('data-page-id', $('.fp-section:eq(' + activeSectionIndex + ')').attr('data-id'));

          // update page <h1> in top bar, sauf sur la page d'accueil
          if (activeSectionIndex == 0) {
            $('#top-bar h1.page-title').addClass('text-hide');
          } else {
            $('#top-bar h1.page-title').removeClass('text-hide').text(SiteConfig.sections[activeSectionIndex].title);
          }
          $('#top-bar .back-to-section').attr('href', '/');
          $('#top-bar .back-to-section').hide().addClass('hidden');

          // close NewsPane
          this.$newsPane.NewsPane('close');
          // close ContactPane
          this.$contactPane.ContactPane('close');
          // close QuotePane
          this.$quotePane.QuotePane('close');
          // close Visite360
          this.$visite360Pane.Visite360Pane('close');
          // close Newsletter
          this.$newsletter.Newsletter('close');
          // close Espace
          this.$espaceDetail.Espace('close');
          // close std page
          app.$stdPage.StdPage('close');
          // close all ToggleIntroDetail
          if ($('body').find('.toggle-intro-detail').data('plugin_ToggleIntroDetail')) {
            $('body').find('.toggle-intro-detail').each(function (index, item) {
              $(this).data('plugin_ToggleIntroDetail').close();
            });
          }

          /*
           sub section actions
           */
          $('.sections-menu .sub-menu > li').removeClass('active');
          this.$subSections.SubSections('close');
        };
        app.subSectionRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          logEvent('subSectionRouteCallback', context.pathname);

          $('body').addClass('subSection');

          var pathParts = context.path.split('/');
          var sectionPathname = "/" + pathParts[0];
          var subSectionPathname = "/" + pathParts[1];

          // paramètre false car on ne veut pas réactiver le fullpage dans ce cas là
          this.closeSlideout(false);

          // section actions
          var activeSection = _.find(SiteConfig.sections, function (o) {
            if (o.path === sectionPathname) {
              return o;
            }
          });
          var activeSectionIndex = SiteConfig.sections.indexOf(activeSection);
          if (app.isResponsiveView()) {
            //$.fn.fullpage.silentMoveTo(activeSectionIndex + 1);
          } else {
            $.fn.fullpage.moveTo(activeSectionIndex + 1);
          }
          // add active class on sections menu item
          $('.sections-menu > li').removeClass('active');
          $('.sections-menu').find('> li > a[href="' + sectionPathname + '"]').parent().addClass('active');

          // add active-1, active-2, etc. sur les siblings du li.active dans le #anchors
          this.$anchors.Anchors('updateActiveStyle');
          this.$anchors.Anchors('enableToggle');

          // close NewsPane
          this.$newsPane.NewsPane('close');
          // close ContactPane
          this.$contactPane.ContactPane('close');
          // close QuotePane
          this.$quotePane.QuotePane('close');
          // close Visite360
          this.$visite360Pane.Visite360Pane('close');
          // close Newsletter
          this.$newsletter.Newsletter('close');
          // close Espace
          this.$espaceDetail.Espace('close');
          // close std page
          app.$stdPage.StdPage('close');

          /*
           sub section actions
           */
          // add active class on sections sub-menu item
          $('.sections-menu .sub-menu > li').removeClass('active');
          $('.sections-menu').find('.sub-menu > li > a[href="' + context.pathname + '"]').parent().addClass('active');

          // update page meta
          var subSectionTitle = $('.sections-menu .sub-menu > li.active a').text();
          updateMeta(subSectionTitle);
          // update page <h1> in top bar, sauf sur la page d'accueil
          $('#top-bar .back-to-section').attr('href', sectionPathname);
          $('#top-bar .back-to-section').removeClass('hidden').show();
          $('#top-bar h1.page-title').removeClass('text-hide').text(subSectionTitle);

          //if (app.router && app.router.lastRoute != context.pathname) {
          this.$subSections.SubSections('open', {
            pathname: context.pathname,
            search: context.querystring != "" ? context.querystring : "filter=0",
            direction: this.subSectionOpenDirection
          });
          //}

          // désactivation du scroll (déjà appelé dans l'événement subSectionOpened mais nécessaire ici dans le cas où le site est chargé avec une URl de sous-section directement)
          if (app.isResponsiveView()) {
            app.disableTouchMove();
          }
          $.fn.fullpage.setAllowScrolling(false, 'all');
          $.fn.fullpage.setKeyboardScrolling(false, 'all');
        };
        app.espaceRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          logEvent('espaceRouteCallback', context.pathname);

          $('body').addClass('espace');

          // param = false car on ne veut pas réactiver le fullpage dans ce cas là
          this.closeSlideout(false);
          // fermeture du ContactPane
          this.$contactPane.ContactPane('close');
          // close QuotePane
          this.$quotePane.QuotePane('close');
          // close Visite360
          this.$visite360Pane.Visite360Pane('close');
          // close Newsletter
          this.$newsletter.Newsletter('close');
          // toggle affichage du NewsPane
          this.$newsPane.NewsPane('close');
          // close std page
          app.$stdPage.StdPage('close');

          // désactivation du toggle Anchors
          this.$anchors.Anchors('close');
          this.$anchors.Anchors('disableToggle');
          this.$anchors.hide();

          // update page <h1> in top bar, sauf sur la page d'accueil
          var href = (a = context.pathname.split('/')).slice(0, a.length - 1).join('/');
          $('#top-bar .back-to-section').attr('href', href);
          $('#top-bar .back-to-section').removeClass('hidden').show();
          $('#top-bar h1.page-title').removeClass('text-hide').text((SiteConfig.current_language == 'fr' ? 'Les espaces' : 'Spaces'));

          // load Espace
          var matchItemId;
          if (matchItemId = context.path.match(/-([0-9]+)$/)) {
            var itemId = matchItemId[1];
            this.$espaceDetail.Espace('loadItem', itemId);
          }
          // on charge la sous-section Les espaces en arrière plan (dans le cas où le site est chargé avec l'URL d'un espace directement)
          /*this.$subSections.SubSections('open', {
           pathname: href,
           search: "filter=0",
           direction: this.subSectionOpenDirection
           });*/
        };
        app.newsListRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          // param = false car on ne veut pas réactiver le fullpage dans ce cas là
          this.closeSlideout(false);
          // fermeture du ContactPane
          this.$contactPane.ContactPane('close');
          // close QuotePane
          this.$quotePane.QuotePane('close');
          // toggle affichage du NewsDetail
          this.$newsDetail.NewsDetail('close');
          // close std page
          app.$stdPage.StdPage('close');

          // toggle affichage du NewsPane
          this.$newsPane.NewsPane('open');

          // désactivation du toggle Anchors
          this.$anchors.Anchors('close');
          this.$anchors.Anchors('disableToggle');

          // update page title
          updateMeta('Actualités');
          // check si on a une section chargée en arrière plan :
          // ce n'est pas le cas si on arrive sur le site via l'URl /actualites directement
          // dans ce cas on va charger le contenu de la section 0
          if ($('.fp-section.loaded').length == 0) {
            app.fetchSectionContent(1, true);
          }
        };
        app.newsDetailRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          // param = false car on ne veut pas réactiver le fullpage dans ce cas là
          this.closeSlideout(false);
          // fermeture du ContactPane
          this.$contactPane.ContactPane('close');
          // close QuotePane
          this.$quotePane.QuotePane('close');
          // close std page
          app.$stdPage.StdPage('close');

          // toggle affichage du NewsPane
          this.$newsPane.NewsPane('open');
          // toggle affichage du NewsDetail
          this.$newsDetail.NewsDetail('open');

          // désactivation du toggle Anchors
          this.$anchors.Anchors('close');
          this.$anchors.Anchors('disableToggle');

          var matchNewsId;
          if (matchNewsId = context.path.match(/-([0-9]+)$/)) {
            var newsId = matchNewsId[1];
            this.$newsDetail.NewsDetail('loadItem', newsId);
          }
          // check si on a une section chargée en arrière plan :
          // ce n'est pas le cas si on arrive sur le site via l'URl /actualites directement
          // dans ce cas on va charger le contenu de la section 0
          if ($('.fp-section.loaded').length == 0) {
            app.fetchSectionContent(1, true);
          }
        };
        app.contactRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          this.closeSlideout(true);
          // close QuotePane
          this.$quotePane.QuotePane('close');

          // toggle affichage du ContactPane
          this.$contactPane.ContactPane('toggle');

          // update page title
          updateMeta('Contact');
          // check si on a une section chargée en arrière plan :
          // ce n'est pas le cas si on arrive sur le site via l'URl /contact directement
          // dans ce cas on va charger le contenu de la section 0
          if ($('.fp-section.loaded').length == 0) {
            app.fetchSectionContent(1, true);
          }
        };
        app.quoteRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          this.closeSlideout(true);
          // toggle affichage du ContactPane
          this.$contactPane.ContactPane('close');

          // toggle afichage du Form de demande de devis
          this.$quotePane.QuotePane('open');

          // update page title
          updateMeta('Demande de devis');
          // check si on a une section chargée en arrière plan :
          // ce n'est pas le cas si on arrive sur le site via l'URl /devis directement
          // dans ce cas on va charger le contenu de la section 0
          if ($('.fp-section.loaded').length == 0) {
            app.fetchSectionContent(1, true);
          }
        };
        app.newsletterRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          this.closeSlideout(true);
          // toggle affichage du ContactPane
          this.$contactPane.ContactPane('close');
          // toggle afichage du Form de demande de devis
          this.$quotePane.QuotePane('close');
          // close Espace
          this.$espaceDetail.Espace('close');
          // close std page
          app.$stdPage.StdPage('close');

          // open Newsletter
          this.$newsletter.Newsletter('open');

          // désactivation du toggle Anchors
          this.$anchors.Anchors('close');
          this.$anchors.Anchors('disableToggle');
          this.$anchors.hide();

          $('#top-bar .back-to-section').addClass('hidden').hide();
          $('#top-bar h1.page-title').addClass('text-hide');

          // update page title
          updateMeta('Newsletter');
          // check si on a une section chargée en arrière plan :
          // ce n'est pas le cas si on arrive sur le site via l'URl /devis directement
          // dans ce cas on va charger le contenu de la section 0
          if ($('.fp-section.loaded').length == 0) {
            app.fetchSectionContent(1, true);
          }
        };
        app.visite360RouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          this.closeSlideout(true);
          // toggle affichage du ContactPane
          this.$contactPane.ContactPane('close');
          // toggle afichage du Form de demande de devis
          this.$quotePane.QuotePane('close');
          // close Espace
          this.$espaceDetail.Espace('close');
          // close std page
          app.$stdPage.StdPage('close');

          // open Visite360
          this.$visite360Pane.Visite360Pane('open');

          // désactivation du toggle Anchors
          this.$anchors.Anchors('close');
          this.$anchors.Anchors('disableToggle');
          this.$anchors.hide();

          $('#top-bar .back-to-section').addClass('hidden').hide();
          $('#top-bar h1.page-title').addClass('text-hide');

          // update page title
          updateMeta('Visite à 360°');
          // check si on a une section chargée en arrière plan :
          // ce n'est pas le cas si on arrive sur le site via l'URl /devis directement
          // dans ce cas on va charger le contenu de la section 0
          if ($('.fp-section.loaded').length == 0) {
            app.fetchSectionContent(1, true);
          }
        };
        app.standardPageRouteCallback = function (context, next) {

          if (context.pathname.match(/#cookies/)) {
            return;
          }

          app.trackPageView(context.pathname);

          // param = false car on ne veut pas réactiver le fullpage dans ce cas là
          this.closeSlideout(false);
          // fermeture du ContactPane
          this.$contactPane.ContactPane('close');
          // close QuotePane
          this.$quotePane.QuotePane('close');
          // toggle affichage du NewsDetail
          this.$newsPane.NewsPane('close');

          // open page
          app.$stdPage.StdPage('open');

          // désactivation du toggle Anchors
          this.$anchors.Anchors('close');
          this.$anchors.Anchors('disableToggle');

          // check si on a une section chargée en arrière plan :
          // ce n'est pas le cas si on arrive sur le site via l'URl /actualites directement
          // dans ce cas on va charger le contenu de la section 0
          if ($('.fp-section.loaded').length == 0) {
            app.fetchSectionContent(1, true);
          }

          // fetchContent
          app.$stdPage.StdPage('loadItem', context.pathname);
        };
        app.router = new Router(app);

      },
      // JavaScript to be fired on all pages, after page specific JS is fired
      finalize: function () {
        $('#global-overlay-loader').delay(500).fadeOut();
      }
    },
    // Home page
    'home': {
      // JavaScript to be fired on the home page
      init: function () {
      },
      // JavaScript to be fired on the home page, after the init JS
      finalize: function () {
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = App;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})
(jQuery); // Fully reference jQuery after this point.
