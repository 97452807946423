/**
 * SubSections jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'SubSections',
        defaults = {
            tweenSpeed: .85,
            app: null,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.$_active = null;
        this._opened = false;
        this.$_scrollWrap = null;
        this.$_gridEspaces = null;
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            self._activate('.loaded');
            self.resize();
        },
        resize: function () {
            var self = this;
            if (self.$_active && self.$_active.length > 0) {
                self.$_active.height(windowHeight() - 60);
                if (self.$_scrollWrap && self.$_scrollWrap.length > 0) {
                    if (self.settings.app.isResponsiveView()) {
                        var contentHeight = self.$_active.height() - 220;
                    } else {
                        var contentHeight = self.$_active.height() - 60;
                    }
                    self.$_scrollWrap.height(contentHeight);
                }
            }
        },
        open: function (data) {
            var self = this;
            //if (self.isOpen() === false) {
            self._opened = true;
            var $previousActive = self.$_active;
            if ($previousActive == null || $previousActive.attr('data-path') != data.pathname) {
                self._activate('[data-path="' + data.pathname + '"]');
                self.resize();
                if (data.direction == 'left') {
                    self.$_active.css('left', '-' + $(window).width() + 'px');
                    TweenLite.to(self.$_active, self.settings.tweenSpeed, {
                        left: 0,
                        onComplete: function () {
                            self.$element.trigger('subSectionOpened', {$target: self.$_active});
                        }
                    });
                    if ($previousActive && $previousActive.attr('data-id') != self.$_active.attr('data-id')) {
                        TweenLite.to($previousActive, self.settings.tweenSpeed, {
                            left: $(window).width(),
                            onComplete: function () {
                                $previousActive.removeClass('active').hide();
                            }
                        });
                    }
                } else if (data.direction == 'right') {
                    self.$_active.css('left', $(window).width() + 'px');
                    TweenLite.to(self.$_active, self.settings.tweenSpeed, {
                        left: 0,
                        onComplete: function () {
                            self.$element.trigger('subSectionOpened', {$target: self.$_active});
                        }
                    });
                    if ($previousActive && $previousActive.attr('data-id') != self.$_active.attr('data-id')) {
                        TweenLite.to($previousActive, self.settings.tweenSpeed, {
                            left: -$(window).width(),
                            onComplete: function () {
                                $previousActive.removeClass('active').hide();
                            }
                        });
                    }
                }
                self.$element.trigger('subSectionOpen', {$target: self.$_active});
            } else {
                if (self.$_active.find('.section-content').find('#grid-espaces').length > 0) {
                    var filter = getQueryVariable('filter', data.search) || 'all';
                    self.$_active.find('.section-content').data('plugin_GridEspaces').filter(filter, false);
                }
            }
            //}
        },
        close: function () {
            var self = this;
            if (self.isOpen() && self.$_active) {
                self._opened = false;
                self.$element.trigger('subSectionClose');
                TweenLite.to(self.$_active, self.settings.tweenSpeed / 2, {
                    left: '-' + ($(window).width()) + 'px',
                    onComplete: function () {
                        if (self.$_active && self.$_active.length > 0) {
                            self.$_active.removeClass('active').hide();
                        }
                        self._deactivate();
                        self.$element.trigger('subSectionClosed');
                    }
                });
            }
        },
        isOpen: function () {
            return this._opened;
        },
        initActiveContent: function () {
            var self = this;
            if (self.$_active) {
                // Y a t'il des scrolls à activer ?
                self.$_scrollWrap = self.$_active.find('.scroll-wrap');
                if (self.$_scrollWrap) {
                    self.$_scrollWrap.mCustomScrollbar({
                        axis: 'y'
                    });
                    self.resize();
                }
                // est-ce la SubSection Espaces ?
                if (self.$_active.find('.section-content').find('#grid-espaces').length > 0) {
                    self.$_active.find('.section-content').GridEspaces({
                        app: self.settings.app,
                        filter: getQueryVariable('filter') || 'all',
                    });
                }
                if (!self.settings.app.isResponsiveView()) {
                    // background image load and tweening
                    // le Tween alpha est fait sur le $subSection.find('.section-content') car on veut garder la background opaque qui est sur $subSection
                    TweenLite.set(self.$_active.find('.section-content'), {
                        autoAlpha: 0
                    });
                }
                // chargement de l'image de background
                if (self.$_active.find('.full-background-single').length > 0) {
                    $('<img/>').attr('src', self.$_active.find('.full-background-single').attr('data-background-image')).load(function () {
                        $(this).remove();
                        self.$_active.find('.full-background-single').css('backgroundImage', 'url(' + self.$_active.find('.full-background-single').attr('data-background-image') + ')');
                        if (!self.settings.app.isResponsiveView()) {
                            TweenLite.set(self.$_active.find('.section-content .content'), {
                                autoAlpha: 0
                            });
                            TweenLite.to(self.$_active.find('.section-content'), 1, {
                                autoAlpha: 1,
                                onComplete: function () {
                                    TweenLite.to(self.$_active.find('.section-content .content'), 1, {
                                        autoAlpha: 1,
                                    });
                                }
                            });
                        }
                    });
                } else {
                    if (!self.settings.app.isResponsiveView()) {
                        TweenLite.to(self.$_active.find('.section-content'), 1, {
                            autoAlpha: 1,
                        });
                    }
                }
            }
        },
        _activate: function (selector) {
            var self = this;
            self._deactivate();
            self.$_active = self.$element.find('.sub-section' + selector).addClass('active');
            if (self.$_active && self.$_active.length > 0) {
                self.$_active.removeClass('hidden').show();
                // on assigne la valeur de l'ID de la sub-section.active à l'attribut global data-page-id sur body
                $('body').attr('data-page-id', self.$_active.attr('data-id'));
            }
        },
        _deactivate: function () {
            var self = this;
            if (self.$_active) {
                self.$_active = null;
            }
        },
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})
(jQuery, window, document);