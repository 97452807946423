/**
 * GridEspaces jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'GridEspaces',
        defaults = {
            responsiveWidth: 768,
            filter: 'all',
            app: null,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.$filter;
        this.$grid;
        this.$gridFiltered;
        this.currentGridView = 'all';//'filtered'
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            // grid
            self.$grid = self.$element.find('#grid-espaces');
            self.$gridFiltered = self.$element.find('#grid-espaces-filtered');
            self.$gridFiltered.hide();
            // filter
            self.$filter = self.$element.find('#filter-espaces');
            self.$filter.find('li a').on('click', function (e) {
                e.preventDefault();
                var filter = parseInt($(this).attr('data-cat')) || 0;
                if (filter > 0) {
                    page(window.location.pathname + '?filter=' + filter);
                } else {
                    page(window.location.pathname);
                }
            });
            if (self.settings.filter != 'all') {
                self.filter(self.settings.filter);
            }
            //
            self.resize();
            $(window).smartResize(function (e) {
                self.resize();
            });
            self.$grid.mCustomScrollbar({
                axis: 'y',
            });
            self.$gridFiltered.mCustomScrollbar({
                axis: 'y',
            });
        },
        filter: function (filter) {
            var filter = parseInt(filter) || 0;
            var self = this;
            self.$filter.find('li').removeClass('active');
            self.$filter.find('li a[data-cat=' + parseInt(filter) + ']').parent().addClass('active');
            if (filter == 0) {
                self.currentGridView = 'all';
                self.$gridFiltered.hide();
                self.$grid.show();
                self.resize();
            } else {
                self.currentGridView = 'filtered';
                self.$grid.hide();
                self.$gridFiltered.find('.grid-item').hide();
                self.$gridFiltered.find('.grid-item.cat-' + filter).show();
                self.$gridFiltered.show();
                self.resize();
            }
        },
        resize: function () {
            var self = this;
            var imgRatio = 16 / 9;
            var divider = 2;
            var itemLargeHeight;
            var itemSmallHeight;
            var itemSmallHeightFiltered;
            if (self.settings.app.isResponsiveView()) {
                itemLargeHeight = (self.$grid.width() / divider) * (1 / imgRatio);
                itemSmallHeight = itemLargeHeight;
                itemSmallHeightFiltered = itemSmallHeight;
            } else {
                imgRatio = 4 / 3;
                divider = self.currentGridView == 'all' ? 2 : 4;
                itemLargeHeight = (self.$grid.width() / divider) * (1 / imgRatio);
                itemSmallHeight = itemLargeHeight / 2;
                itemSmallHeightFiltered = (self.$gridFiltered.width() / divider) * (1 / imgRatio);
            }

            // grid
            self.$grid.find('.grid-item-large').height(itemLargeHeight);
            self.$grid.find('.grid-item-small').height(itemSmallHeight);

            self.$grid.height(windowHeight() - 60 - 80);

            // gridFiltered
            self.$gridFiltered.find('.grid-item-small').height(itemSmallHeightFiltered);

            self.$gridFiltered.height(windowHeight() - 60 - 80);
        },
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);