/**
 * ToggleIntroDetail jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'ToggleIntroDetail',
        defaults = {
            responsiveWidth: 768,
            app: null,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        //this.$grosTitre = this.$element.find('.gros-titre');
        this.$intro = this.$element.find('.intro');
        this.$detail = this.$element.find('.detail');
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            self.resize();
            $(window).smartResize(function (e) {
                self.resize();
            });
            // setup .intro
            self.$intro.find('.more').on('click', function (e) {
                e.preventDefault();
                self.open();
            });
            self.$intro.find('.scroll-wrap').mCustomScrollbar({
                axis: 'y',
            });
            // setup .detail
            self.$detail.find('.btn-close').on('click', function (e) {
                e.preventDefault();
                self.close();
            });
            self.$detail.find('.scroll-wrap').mCustomScrollbar({
                axis: 'y',
            });
            //
            self.buildAnchors();
        },
        open: function () {
            var self = this;
            self.$element.addClass('toggle');
            if (self.settings.app.isResponsiveView()) {
                // sur mobile, on doit désactiver le router le temps que la transition CSS se fasse
                // sinon ça génère un popstate event et provoque une changement de section
                self.settings.app.router.stop();
                // on redémarre le router après un délai équivalent à la durée de la transition CSS (600ms en CSS, on met un peu plus pour être sûr...)
                setTimeout(function () {
                    self.settings.app.router.start();
                }, 650);
                $(document).scrollTop(self.$element.closest('.section').position().top);
            }
        },
        close: function () {
            var self = this;
            if (self.settings.app.isResponsiveView()) {
                // sur mobile, on doit désactiver le router le temps que la transition CSS se fasse
                // sinon ça génère un popstate event et provoque une changement de section
                self.settings.app.router.stop();
                // on redémarre le router après un délai équivalent à la durée de la transition CSS (600ms en CSS, on met un peu plus pour être sûr...)
                setTimeout(function () {
                    self.settings.app.router.start();
                }, 650);
            }
            self.resetDetailContent();
            self.$element.removeClass('toggle');
        },
        resize: function () {
            var self = this;
            if ($(window).width() < self.settings.responsiveWidth) {
                self.$element.height('auto');
                self.$intro.find('.scroll-wrap').height('auto');
                self.$detail.find('.scroll-wrap').height('auto');
            } else {
                self.$element.height(windowHeight() - 60);
                var bottomOffset = 90;
                if (self.$detail.length > 0) {
                    bottomOffset = 170;
                }
                var innerHeight = self.$element.height() - bottomOffset;
                self.$intro.find('.scroll-wrap').height(innerHeight);
                self.$detail.find('.scroll-wrap').height(innerHeight);
            }
        },
        buildAnchors: function () {
            var self = this;
            self.$detail.find('.scroll-wrap h3').each(function (index, item) {
                self.$detail.find('.anchors').append('<li><a href="#">' + $(item).text() + '</a></li>');
            });
            self.$detail.find('.anchors li a').on('click', function (e) {
                e.preventDefault();
                var index = $(this).closest('li').index();
                self.$detail.find('.scroll-wrap .content').find('*').hide();
                var $heading = self.$detail.find('.scroll-wrap .content').find('h3').eq(index);
                $heading.show();
                $heading.next('p').show();
                //self.$detail.find('.scroll-wrap').mCustomScrollbar("scrollTo", self.$detail.find('.scroll-wrap h3:eq(' + index + ')'));
            });
        },
        resetDetailContent: function () {
            var self = this;
            self.$detail.find('.scroll-wrap .content').find('*').show();
        }
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);