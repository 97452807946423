/**
 * Espace jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'Espace',
        defaults = {
            tweenSpeed: 0.5,
            tweenDelay: 0.5,
            responsiveWidth: 768,
            maxTopCSSPositionWhenOpen: 60, // topBar height
            app: null,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this._opened = false;
        this._entryHBS = $("#espace-detail-template").html();
        this._entryTemplate = Handlebars.compile(this._entryHBS);
        this.$entry = this.$element.find('.entry');
        this.$loader = this.$element.find('.loader');
        // les props ci-dessous seront set après le chargement du contenu via Ajax
        this.$infos;
        this.$viewer;
        this.$switch;
        this.currentItemId;
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            Handlebars.registerHelper('__pluralize', function (count, singular, plural) {
                return count == 1 ? singular : plural;
            });
            self.resize();
            //self.$element.hide();
            $(window).smartResize(function (e) {
                self.resize();
            });
        },
        resize: function () {
            var self = this;
            // resize infos
            if (self.$infos) {
                if (self.settings.app.isResponsiveView()) {
                    self.$infos.height(windowHeight() - 60 - 340);
                } else {
                    self.$infos.height(windowHeight() - 60 - 140);
                }
            }
            // resize viewer
            self.resizeViewer();
        },
        resizeViewer: function () {
            var self = this;
            if (self.$viewer) {
                // photos
                var $photoWrap = self.$viewer.find('.photos .items .item.active .photo-wrap');
                var $img = $photoWrap.find('img');
                if (self.settings.app.isResponsiveView()) {
                    $photoWrap.height('auto');
                } else {
                    var photosContainerHeight = Math.min(
                        $img.height(),
                        windowHeight() - self.$viewer.find('.photos .items').offset().top - 75
                    );
                    $photoWrap.height(photosContainerHeight);
                    if ($img.height() > photosContainerHeight) {
                        $img.css('marginTop', -(($img.height() - photosContainerHeight) / 2));
                    }
                    var photosControlsTop = photosContainerHeight;
                    self.$viewer.find('.photos .controls').css('top', photosControlsTop);
                }
                function resizeIframes(target) {
                    var $iframe = self.$viewer.find(target + ' .item.active iframe');
                    var w = $iframe.width();
                    var h = $iframe.height();
                    var r = w / h;
                    $iframe.width(self.$viewer.width());
                    $iframe.height(self.$viewer.width() / r);
                }

                // vidéos
                resizeIframes('.video');
                // v360
                resizeIframes('.v360');
            }
        },
        open: function () {
            var self = this;
            if (self.isOpen() === false) {
                self.$element.trigger('espaceDetailOpen');
                self.$element.removeClass('hidden').show();
                self._opened = true;
                TweenLite.set(self.$element, {
                    top: windowHeight()
                });
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    top: self._topCSSPositionWhenOpen() + 'px',
                    onComplete: function () {
                        self.resize();
                        self.$element.trigger('espaceDetailOpened');
                    }
                });
            }
        },
        close: function () {
            var self = this;
            if (self.isOpen()) {
                self.$element.trigger('espaceDetailClose');
                self._opened = false;
                self.stopIframes('.video', 'all');
                self.stopIframes('.v360', 'all');
                TweenLite.to(this.$element, this.settings.tweenSpeed, {
                    top: windowHeight(),
                    onComplete: function () {
                        self.$switch.filter('.hidden-xs').find('a:eq(0)').trigger('click');
                        self.$element.hide();
                        self.$element.trigger('espaceDetailClosed');
                    }
                });
            }
        },
        toggle: function () {
            var self = this;
            return self.isOpen() ? self.close() : self.open();
        },
        isOpen: function () {
            var self = this;
            return self._opened;
        },
        loadItem: function (id) {
            var self = this;
            if (self.isOpen() === false) {
                self.open();
                //self.resize();
            }
            if (id != self.currentItemId) {
                self.fetch(id);
            } else {
                self.initIframes('.video');
                self.initIframes('.v360');
            }
        },
        fetch: function (id) {
            var self = this;
            self.$entry.empty();
            TweenLite.set(self.$loader, {
                autoAlpha: 1
            });
            self.$loader.removeClass('hidden');
            TweenLite.to(self.$loader, .5, {
                autoAlpha: 1
            });
            $.getJSON(SiteConfig.home_url + '/wp-json/wp/v2/espaces/' + id, function (item) {
                if (item.id) {
                    self.currentItemId = item.id;
                    var $entryContent = $(self._entryTemplate(item));
                    self.$entry.html($entryContent);
                    // update page title
                    updateMeta(item.title.rendered);
                    //
                    self.initContent();
                }
                TweenLite.to(self.$loader, .5, {
                    autoAlpha: 0
                });
            });
        },
        _topCSSPositionWhenClosed: function () {
            var self = this;
            return windowHeight() - self.$paneHeader.height();
        },
        _topCSSPositionWhenOpen: function () {
            var self = this;
            return self.settings.maxTopCSSPositionWhenOpen;
        },
        initContent: function () {
            var self = this;
            self.$infos = self.$entry.find('.infos');
            self.$viewer = self.$entry.find('.media-viewer');
            self.$switch = self.$entry.find('.media-viewer-switch');
            // PHOTOS
            self.initPhotos();
            // VIDEOS
            self.initIframes('.video');
            // GALLERY 360
            self.initIframes('.v360');
            // switch view
            self.$switch.find('a').on('click', function (e) {
                e.preventDefault();
                var $this = $(this);
                var target = $this.attr('data-target');
                var $target = self.$viewer.find('.' + target);
                if (!$this.hasClass('active')) {
                    switch (target) {
                        case 'photos':
                            self.stopIframes('.video', 'all');
                            self.stopIframes('.v360', 'all');
                            break;
                        case 'video':
                            self.stopIframes('.v360', 'all');
                            break;
                        case 'v360':
                            self.stopIframes('.video', 'all');
                            break;
                    }
                    // active class
                    self.$switch.find('a').removeClass('active');
                    $this.addClass('active');
                    // toggle viewer
                    self.$viewer.find('> div').removeClass('hidden').hide();
                    $target.show();
                    switch (target) {
                        case 'video':
                            //self.initIframes('.video');
                            break;
                        case 'v360':
                            //self.initIframes('.v360');
                            break;
                    }
                    // resize viewer
                    self.resizeViewer();
                }
            });
            self.resize();
            self.$infos.mCustomScrollbar({
                axis: 'y',
            });
        },
        initPhotos: function () {
            var self = this;
            var loadItem = function ($item) {
                var showItem = function ($item) {
                    $item.show();
                    TweenLite.set($item, {
                        autoAlpha: 0
                    });
                    TweenLite.to($item, .5, {
                        autoAlpha: 1
                    });
                };
                $item.addClass('active');
                var $img = $item.find('img');
                $img.on('load', function () {
                    showItem($item);
                    self.resizeViewer();
                });
                if ($img.attr('src') == undefined) {
                    $img.attr('src', $img.attr('data-src'));
                } else {
                    showItem($item);
                    self.resizeViewer();
                }
            };
            var numItems = self.$viewer.find('.photos .items .item').length;
            if (numItems <= 1) {
                self.$viewer.find('.photos .controls').hide();
            }
            self.$viewer.find('.photos .items .item').hide();
            var $firstItem = self.$viewer.find('.photos .items .item:eq(0)');
            loadItem($firstItem);
            self.$viewer.find('.photos .controls a').on('click', function (e) {
                e.preventDefault();
                var action = $(this).attr('data-action');
                var $currentItem = self.$viewer.find('.photos .items .item.active');
                var currentIndex = $currentItem.index();
                $currentItem.hide().removeClass('active');
                var nextIndex;
                switch (action) {
                    case 'prev':
                        if (currentIndex > 0) {
                            nextIndex = currentIndex - 1;
                        } else {
                            nextIndex = numItems - 1;
                        }
                        break;
                    case 'next':
                        if (currentIndex < numItems - 1) {
                            nextIndex = currentIndex + 1;
                        } else {
                            nextIndex = 0;
                        }
                        break;
                }
                var $nextItem = self.$viewer.find('.photos .items .item:eq(' + nextIndex + ')');
                loadItem($nextItem);
            });
            self.$viewer.find('.photos .items .item').on('click', function () {
                self.$viewer.find('.photos .controls a[data-action=next]').trigger('click');
            });
        },
        initIframes: function (target) {
            var self = this;
            //self.stopIframes(target, 'all');
            var loadItem = function ($item) {
                var showItem = function ($item) {
                    $item.show();
                };
                $item.addClass('active');
                var $iframe = $item.find('iframe');
                var onLoad = function () {
                    $iframe.off('load', onLoad);
                    showItem($item);
                };
                $iframe.on('load', onLoad);

                showItem($item);

                self.resizeViewer();
            };
            var numItems = self.$viewer.find(target + ' .items .item').length;
            if (numItems <= 1) {
                self.$viewer.find(target + ' .controls').hide();
            }
            self.$viewer.find(target + ' .items .item').hide();
            var $firstItem = self.$viewer.find(target + ' .items .item:eq(0)');
            loadItem($firstItem);
            self.$viewer.find(target + ' .controls a').off('click');
            self.$viewer.find(target + ' .controls a').on('click', function (e) {
                e.preventDefault();
                var $currentItem = self.$viewer.find(target + ' .items .item.active');
                var currentIndex = $currentItem.index();
                $currentItem.hide().removeClass('active');
                self.stopIframes(target, $currentItem);
                var nextIndex;
                var action = $(this).attr('data-action');
                switch (action) {
                    case 'prev':
                        if (currentIndex > 0) {
                            nextIndex = currentIndex - 1;
                        } else {
                            nextIndex = numItems - 1;
                        }
                        break;
                    case 'next':
                        if (currentIndex < numItems - 1) {
                            nextIndex = currentIndex + 1;
                        } else {
                            nextIndex = 0;
                        }
                        break;
                }
                var $nextItem = self.$viewer.find(target + ' .items .item:eq(' + nextIndex + ')');
                loadItem($nextItem);
            });
        },
        stopIframes: function (target, $item) {
            var self = this;
            $item = $item || 'all';
            if ($item == 'all') {
                self.$viewer.find(target + ' .items .item').each(function () {
                    $(this).find('iframe').attr('src', $(this).find('iframe').attr('src'));
                });
            } else {
                $item.find('iframe').attr('src', $(this).find('iframe').attr('src'));
            }
        },
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);