/**
 * SimpleToggle jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'SimpleToggle',
        defaults = {
            // Automatically adds a click event handler on the $element (auto toggle)
            auto: true,
            toggleOnClassName: 'toggle-on',
            toggleOffClassName: 'toggle-off'
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        if (this.settings.target) {
            this.$target = $(this.settings.target);
        } else {
            this.$target = this.$element;
        }
        this._defaults = defaults;
        this._name = pluginName;
        this.toggleStatus = this.$element.hasClass(this.settings.toggleOnClassName);
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            if (self.settings.auto) {
                this.$element.on('click', function () {
                    self.toggle();
                });
            }
        },
        open: function () {
            var self = this;
            if (self.toggleStatus === false) {
                self.$target.removeClass(self.settings.toggleOffClassName).addClass(self.settings.toggleOnClassName);
                self.toggleStatus = true;
            }
        },
        close: function () {
            var self = this;
            if (self.toggleStatus) {
                self.$target.removeClass(self.settings.toggleOnClassName).addClass(self.settings.toggleOffClassName);
                self.toggleStatus = false;
            }
        },
        toggle: function () {
            var self = this;
            if (self.toggleStatus) {
                self.close();
            } else {
                self.open();
            }
        },
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);