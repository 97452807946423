/**
 * StdPage jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'StdPage',
        defaults = {
            tweenSpeed: 0.5,
            tweenDelay: 0.5,
            // this prop stores the route state of the app just before the pane opens, used to go back to that route when pane closes
            routeOnClose: "/",
            maxTopCSSPositionWhenOpen: 60, // topBar height
            app: null,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this._opened = false;
        this._entryHBS = $("#page-standard-template").html();
        this._entryTemplate = Handlebars.compile(this._entryHBS);
        this.$entry = this.$element.find('.entry');
        this.$paneHeader = this.$entry.find('.pane-header');
        this.$paneBody = this.$entry.find('.pane-body');
        this.currentPage;
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            self.resize();
            self.currentPage = self.$entry.find('.container').attr('data-post-name');
            self.$paneBody.mCustomScrollbar({
                axis: 'y',
            });
        },
        resize: function () {
            var self = this;
            if (self.isOpen()) {
                var top = self._topCSSPositionWhenOpen();
                self.$element.css('top', top + 'px');
                if (top <= self.settings.maxTopCSSPositionWhenOpen) {
                    var vOffset;
                    if (self.settings.app.isResponsiveView()) {
                        vOffset = 76;
                    } else {
                        vOffset = 140;
                    }
                    self.$paneBody.height(windowHeight() - top - vOffset);
                } else {
                    self.$paneBody.height('auto');
                    var top = self._topCSSPositionWhenOpen();
                    self.$element.css('top', top + 'px');
                }
                // resize iframes
                self.$entry.find('iframe').each(function () {
                    var $this = $(this);
                    if ($this.attr('data-src-ratio') == undefined) {
                        $this.attr('data-src-ratio', $this.width() / $this.height());
                    }
                    $this.width('100%');
                    $this.height($this.width() / $this.attr('data-src-ratio'));
                });
            } else {
                self.$element.css('top', self._topCSSPositionWhenClosed() + 'px');
            }
        },
        setRouteOnClose: function (value) {
            var self = this;
            self.routeOnClose = value;
            self.$element.find('.btn-close').attr('href', value);
        },
        open: function () {
            var self = this;
            if (self.isOpen() === false) {
                self.$element.trigger('stdPageOpen');
                self.$element.removeClass('hidden').show();
                self._opened = true;
                TweenLite.set(self.$element, {
                    top: windowHeight()
                });
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    top: self._topCSSPositionWhenOpen() + 'px',
                    onComplete: function () {
                        self.resize();
                        self.$element.trigger('stdPageOpened');
                    }
                });
            }
        },
        close: function () {
            var self = this;
            if (self.isOpen()) {
                self.$element.trigger('stdPageClose');
                self._opened = false;
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    top: windowHeight(),
                    onComplete: function () {
                        self.$entry.hide();
                        self.$element.hide();
                        self.$element.trigger('stdPageClosed');
                    }
                });
            }
        },
        toggle: function () {
            return this.isOpen() ? this.close() : this.open();
        },
        isOpen: function () {
            return this._opened;
        },
        _topCSSPositionWhenClosed: function () {
            var self = this;
            return windowHeight() - self.$paneHeader.height();
        },
        _topCSSPositionWhenOpen: function () {
            var self = this;
            return self.settings.maxTopCSSPositionWhenOpen;
        },
        loadItem: function (pathname) {
            var self = this;
            pathname = pathname.substr(1);
            if (self.currentPage != pathname) {
                self.fetch(pathname);
            } else {
                self.$entry.show();
            }
        },
        fetch: function (pathname) {
            var self = this;
            $.getJSON(SiteConfig.home_url + '/wp-json/wp/v2/pages?slug=' + pathname, function (items) {
                if (items.length > 0) {
                    var item = items[0];
                    if (item.id) {
                        var $entryContent = $(self._entryTemplate(item));
                        self.$entry.html($entryContent);
                        self.$entry.show();
                        self.$paneBody = self.$entry.find('.pane-body')
                        self.$paneBody.mCustomScrollbar({
                            axis: 'y',
                        });
                        self.resize();
                        // update page title
                        updateMeta(item.title.rendered);
                    }
                }
            });
        },
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);
