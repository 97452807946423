/**
 * Met à jour le <title>
 *
 * @param title
 */
function updateMeta(title) {

    // Update <title> meta
    var meta_title = '';
    if (title != 'Accueil' && title != 'Home') {
        meta_title += title + ' - ';
    }
    meta_title += SiteConfig.site_title;
    $('title').text(meta_title);

}

/**
 * Retourne la hauteur réelle de la fenêtre quel que soit le device (sur iPhone il faut retourner window.innerHeight)
 * @returns {*}
 */
function windowHeight() {
    var _wHeight;
    if (window.innerHeight) {
        _wHeight = window.innerHeight;
    } else {
        _wHeight = $(window).height();
    }
    return _wHeight;
}

/**
 * Get GET query variable from URL
 * @param variable
 * @returns {string}
 */
function getQueryVariable(variable, query) {
    var query = query || window.location.search.match(/^\??(.*)$/)[1];
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}