/**
 * NewsDetail jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'NewsDetail',
        defaults = {
            tweenSpeed: 0.5,
            tweenDelay: 0.5,
            responsiveWidth: 768,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this._opened = false;
        this._entryHBS = $("#news-detail-template").html();
        this._entryTemplate = Handlebars.compile(this._entryHBS);
        this.$entry = this.$element.find('.entry');
        this.$loader = this.$element.find('.loader');
        this.currentNewsId;
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            Handlebars.registerHelper('__featured_detail', function (_embedded) {
                if (_embedded['wp:featuredmedia']) {
                    var file_path;
                    if (_embedded['wp:featuredmedia'][0].media_details.sizes.medium_large) {
                        file_path = _embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url;
                    } else {
                        file_path = _embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url;
                    }
                    return '<img src="' + file_path + '" class="featured">';
                }
            });
            Handlebars.registerHelper('__date', function (date) {
                var d = new Date(date);
                //return d.getDate() + ' ' + d.getMonth() + ' ' + d.getFullYear();
                return moment(d).format('LL');
            });
            self.resize();
            self.$element.hide();
            self.$entry.mCustomScrollbar({
                axis: 'y',
            });
        },
        resize: function () {
            var self = this;
            self.$element.height(windowHeight() - 60);
            if (self.isOpen() === false) {
                var responsiveLeft = 'auto';
                if ($(window).width() < self.settings.responsiveWidth) {
                    responsiveLeft = '-' + self.$element.width() + 'px';
                }
                self.$element.css('left', responsiveLeft);
            }
            var entryHeight = self.$element.height() - self.$entry.css('padding-top').replace(/[^-\d.]/g, '') * 2;
            self.$entry.height(entryHeight);
            // resize iframes
            self.$entry.find('iframe').each(function () {
                var $this = $(this);
                if ($this.attr('data-src-ratio') == undefined) {
                    $this.attr('data-src-ratio', $this.width() / $this.height());
                }
                $this.width('100%');
                $this.height($this.width() / $this.attr('data-src-ratio'));
            });
        },
        open: function () {
            var self = this;
            if (self.isOpen() === false) {
                $('body').trigger('newsDetailOpen');
                self._opened = true;
                var responsiveLeft = '45%';
                if ($(window).width() < self.settings.responsiveWidth) {
                    responsiveLeft = '0px';
                }
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    left: responsiveLeft,
                    delay: self.settings.tweenDelay,
                    onComplete: function () {
                        $('body').trigger('newsDetailOpened');
                    }
                });
            }
        },
        close: function () {
            var self = this;
            if (self.isOpen()) {
                $('body').trigger('newsDetailClose');
                this._opened = false;
                var responsiveLeft = 0;
                if ($(window).width() < this.settings.responsiveWidth) {
                    responsiveLeft = '-' + self.$element.width() + 'px';
                }
                TweenLite.to(this.$element, this.settings.tweenSpeed, {
                    left: responsiveLeft,
                    onComplete: function () {
                        self.$element.hide();
                        self.$element.trigger('newsDetailClosed');
                    }
                });
            }
        },
        toggle: function () {
            return this.isOpen() ? this.close() : this.open();
        },
        isOpen: function () {
            return this._opened;
        },
        loadItem: function (id) {
            if (this.isOpen() === false) {
                this.open();
            }
            if (id != this.currentNewsId) {
                this.fetch(id);
            }
        },
        fetch: function (id) {
            var self = this;
            self.$entry.find('.mCSB_container').empty();
            TweenLite.set(self.$loader, {
                autoAlpha: 1
            });
            self.$loader.removeClass('hidden');
            TweenLite.to(self.$loader, .5, {
                autoAlpha: 1
            });
            $.getJSON(SiteConfig.home_url + '/wp-json/wp/v2/posts/' + id, {
                _embed: true
            }, function (item) {
                if (item.id) {
                    self.currentNewsId = item.id;
                    var $entryContent = $(self._entryTemplate(item));
                    //self.$entry.html($entryContent);
                    self.$entry.find('.mCSB_container').html($entryContent);
                    self.$entry.mCustomScrollbar('update');
                    self.resize();
                    // update page title
                    updateMeta(item.title.rendered);
                }
                TweenLite.to(self.$loader, .5, {
                    autoAlpha: 0
                });
            });
        }
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);
