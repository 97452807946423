/**
 * Anchors jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'Anchors',
        defaults = {
            tweenSpeed: 0.5,
            tweenDelay: 0.5,
            thresholdX: 25,
            thresholdYMin: 60, // topbar height
            thresholdYMax: 260,
            enabled: true,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this._opened = true;
        this._closeTimeout;
        this.$_nav = this.$element.find('.anchors-nav');
        this.$_toggle = this.$element.find('.anchors-toggle');
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;

            //self.enableToggle();

            // styles au rollover
            self.$_nav.find('li').hover(
                function () {
                    self._updateOverStyle($(this));
                }, function () {
                    self._updateOutStyle($(this));
                }
            );
        },
        _updateOverStyle: function ($target) {
            var self = this;
            // désactivation des classes active
            self.$_nav.find('li.active').addClass('_active').removeClass('active');
            self.$_nav.find('li').removeClass('active-prev-1 active-prev-2 active-next-1 active-next-2');
            // ajout des classes hover
            $target.addClass('hover');
            $target.prev().addClass('hover-prev-1').prev().addClass('hover-prev-2');
            $target.next().addClass('hover-next-1').next().addClass('hover-next-2');
        },
        _updateOutStyle: function () {
            var self = this;
            // retrait des classes hover
            self.$_nav.find('li').removeClass('hover hover-prev-1 hover-prev-2 hover-next-1 hover-next-2');
            // réactivation des classes active
            self.$_nav.find('li').removeClass('active-prev-1 active-prev-2 active-next-1 active-next-2');
            self.$_nav.find('li._active').addClass('active').removeClass('_active');
            self.$_nav.find('li.active').prev().addClass('active-prev-1').prev().addClass('active-prev-2');
            self.$_nav.find('li.active').next().addClass('active-next-1').next().addClass('active-next-2');
        },
        updateActiveStyle: function () {
            var self = this;
            self.$_nav.find('li').removeClass('_active active-prev-1 active-prev-2 active-next-1 active-next-2 hover hover-prev-1 hover-prev-2 hover-next-1 hover-next-2');
            self.$_nav.find('li.active').prev().addClass('active-prev-1').prev().addClass('active-prev-2');
            self.$_nav.find('li.active').next().addClass('active-next-1').next().addClass('active-next-2');
        },
        enableToggle: function () {
            var self = this;
            if (self.settings.enabled) {
                $(document).on("mousemove", self, self.mouseMoveHandler);
            }
        },
        disableToggle: function () {
            var self = this;
            if (self.settings.enabled) {
                clearTimeout(self._closeTimeout);
                self._closeTimeout = undefined;
                $(document).off("mousemove", self.mouseMoveHandler);
            }
        },
        mouseMoveHandler: function (e) {
            var self = e.data;
            if (self._isMouseInArea(e, self.$_nav.width()) && self.isOpen()) {
                // si le menu est déjà ouvert et que la souris est dans la zone complète
                // on annule le closeTimeout
                clearTimeout(self._closeTimeout);
                self._closeTimeout = undefined;
            } else if (self._isMouseInArea(e, self.settings.thresholdX) && self.isOpen() === false) {
                // si le menu est fermé et que la souris est dans la zone réduite
                // on annule le closeTimeout et on ouvre le menu
                clearTimeout(self._closeTimeout);
                self._closeTimeout = undefined;
                self.open();
            } else if (!self._isMouseInArea(e, self.$_nav.width()) && self.isOpen()) {
                // si le menu est ouvert et que la souris n'est pas dans la zone complète
                // on démarre le closeTimeout
                if (self._closeTimeout === undefined) {
                    self.setCloseTimeout();
                }
            }
        },
        _isMouseInArea: function (e, thresholdX) {
            var self = this;
            if ($(window).width() - e.pageX < thresholdX
                && e.pageY > self.settings.thresholdYMin
                && e.pageY < ($(window).height() + self.$_nav.height()) / 2 + 30) {
                return true;
            }
            return false;
        },
        setCloseTimeout: function () {
            var self = this;
            self._closeTimeout = setTimeout(function () {
                self.close();
            }, 250);
        },
        open: function () {
            var self = this;
            if (self.isOpen() === false && self.settings.enabled) {
                self.$element.trigger('anchorsOpen');
                self._opened = true;
                self.$_toggle.hide();
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    right: '0px',
                });
            }
        },
        close: function () {
            var self = this;
            if (self.isOpen() && self.settings.enabled) {
                self._opened = false;
                // on reset le href du bouton d'ouverture
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    right: '-' + (self.$_nav.width()) + 'px',
                    onComplete: function () {
                        self.$element.trigger('anchorsClose');
                        self.$_toggle.removeClass('hidden').show();
                    }
                });
            }
        },
        toggle: function () {
            return this.isOpen() ? this.close() : this.open();
        },
        isOpen: function () {
            return this._opened;
        },
        updatePosition: function () {
            this.$element.find('.tableCell').height($(window).height());
        },
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);