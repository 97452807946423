/*!
 * CE SCRIPT EST UN FORK de fullPage 2.7.8 (https://github.com/alvarotrigo/fullPage.js)
 * pour les besoins spécifiques du site Royaumont
 * CE FICHIER NE DOIT PAS ETRE MIS A JOUR AVEC UNE VERSION PLUS RECENTE DE fullPage.js
 */
(function (global, factory) {
    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], function ($) {
            return factory($, global, global.document, global.Math);
        });
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'), global, global.document, global.Math);
    } else {
        factory(jQuery, global, global.document, global.Math);
    }
})(typeof window !== 'undefined' ? window : this, function ($, window, document, Math, undefined) {
    'use strict';

    // keeping central set of classnames and selectors
    var WRAPPER = 'fullpage-wrapper';
    var WRAPPER_SEL = '.' + WRAPPER;

    // slimscroll
    var SCROLLABLE = 'fp-scrollable';
    var SCROLLABLE_SEL = '.' + SCROLLABLE;
    var SLIMSCROLL_BAR_SEL = '.slimScrollBar';
    var SLIMSCROLL_RAIL_SEL = '.slimScrollRail';

    // util
    var RESPONSIVE = 'fp-responsive';
    var NO_TRANSITION = 'fp-notransition';
    var DESTROYED = 'fp-destroyed';
    var ENABLED = 'fp-enabled';
    //var VIEWING_PREFIX = 'fp-viewing';
    var ACTIVE = 'active';
    var ACTIVE_SEL = '.' + ACTIVE;
    var COMPLETELY = 'fp-completely';
    var COMPLETELY_SEL = '.' + COMPLETELY;

    // section
    var SECTION_DEFAULT_SEL = '.section';
    var SECTION = 'fp-section';
    var SECTION_SEL = '.' + SECTION;
    var SECTION_ACTIVE_SEL = SECTION_SEL + ACTIVE_SEL;
    var SECTION_FIRST_SEL = SECTION_SEL + ':first';
    var SECTION_LAST_SEL = SECTION_SEL + ':last';
    var TABLE_CELL = 'fp-tableCell';
    var TABLE_CELL_SEL = '.' + TABLE_CELL;
    var AUTO_HEIGHT = 'fp-auto-height';
    //var AUTO_HEIGHT_SEL = '.fp-auto-height';
    var NORMAL_SCROLL = 'fp-normal-scroll';
    var NORMAL_SCROLL_SEL = '.' + NORMAL_SCROLL;

    var $window = $(window);
    var $document = $(document);

    var defaultScrollHandler;

    $.fn.fullpage = function (options) {
        //only once my friend!
        if ($('html').hasClass(ENABLED)) {
            displayWarnings();
            return;
        }

        // common jQuery objects
        var $htmlBody = $('html, body');
        var $body = $('body');

        var FP = $.fn.fullpage;
        // Create some defaults, extending them with any options that were provided
        options = $.extend({
            //navigation
            anchors: [],
            lockAnchors: false,
            navigation: false,
            navigationPosition: 'right',
            navigationTooltips: [],
            showActiveTooltip: false,
            scrollBar: false,
            hybrid: false,

            //scrolling
            css3: true,
            scrollingSpeed: 700,
            autoScrolling: true,
            fitToSection: true,
            fitToSectionDelay: 1000,
            easing: 'easeInOutCubic',
            easingcss3: 'ease',
            loopBottom: false,
            loopTop: false,
            loopHorizontal: true,
            continuousVertical: false,
            normalScrollElements: null,
            scrollOverflow: false,
            scrollOverflowHandler: defaultScrollHandler,
            touchSensitivity: 5,
            normalScrollElementTouchThreshold: 5,

            //Accessibility
            keyboardScrolling: true,
            animateAnchor: true,
            //recordHistory: true,

            //design
            controlArrows: true,
            controlArrowColor: '#fff',
            verticalCentered: true,
            resize: false,
            sectionsColor: [],
            paddingTop: 0,
            paddingBottom: 0,
            fixedElements: null,
            responsive: 0, //backwards compabitility with responsiveWiddth
            responsiveWidth: 0,
            responsiveHeight: 0,

            //Custom selectors
            sectionSelector: SECTION_DEFAULT_SEL,

            //events
            afterLoad: null,
            onLeave: null,
            afterRender: null,
            afterResize: null,
            afterReBuild: null
        }, options);

        FP.options = options;

        displayWarnings();

        //easeInOutCubic animation included in the plugin
        $.extend($.easing, {
            easeInOutCubic: function (x, t, b, c, d) {
                if ((t /= d / 2) < 1) {
                    return c / 2 * t * t * t + b;
                }
                return c / 2 * ((t -= 2) * t * t + 2) + b;
            }
        });

        /**
         * Sets the autoScroll option.
         * It changes the scroll bar visibility and the history of the site as a result.
         */
        FP.setAutoScrolling = function (value, type) {
            setVariableState('autoScrolling', value, type);

            var element = $(SECTION_ACTIVE_SEL);

            if (options.autoScrolling && !options.scrollBar) {
                $htmlBody.css({
                    'overflow': 'hidden',
                    'height': '100%'
                });

                //FP.setRecordHistory(originals.recordHistory, 'internal');

                //for IE touch devices
                container.css({
                    '-ms-touch-action': 'none',
                    'touch-action': 'none'
                });

                if (element.length) {
                    //moving the container up
                    silentScroll(element.position().top);
                }

            } else {
                $htmlBody.css({
                    'overflow': 'visible',
                    'height': 'initial'
                });

                //FP.setRecordHistory(false, 'internal');

                //for IE touch devices
                container.css({
                    '-ms-touch-action': '',
                    'touch-action': ''
                });

                silentScroll(0);

                //scrolling the page to the section with no animation
                if (element.length) {
                    $htmlBody.scrollTop(element.position().top);
                }
            }
        };

        /**
         * Defines wheter to record the history for each hash change in the URL.
         */
        /*FP.setRecordHistory = function (value, type) {
         setVariableState('recordHistory', value, type);
         };*/

        /**
         * Defines the scrolling speed
         */
        FP.setScrollingSpeed = function (value, type) {
            setVariableState('scrollingSpeed', value, type);
        };

        /**
         * Sets fitToSection
         */
        FP.setFitToSection = function (value, type) {
            setVariableState('fitToSection', value, type);
        };

        /**
         * Sets lockAnchors
         */
        FP.setLockAnchors = function (value) {
            options.lockAnchors = value;
        };

        /**
         * Adds or remove the possiblity of scrolling through sections by using the mouse wheel or the trackpad.
         */
        FP.setMouseWheelScrolling = function (value) {
            if (value) {
                addMouseWheelHandler();
                addMiddleWheelHandler();
            } else {
                removeMouseWheelHandler();
                removeMiddleWheelHandler();
            }
        };

        /**
         * Adds or remove the possiblity of scrolling through sections by using the mouse wheel/trackpad or touch gestures.
         * Optionally a second parameter can be used to specify the direction for which the action will be applied.
         *
         * @param directions string containing the direction or directions separated by comma.
         */
        FP.setAllowScrolling = function (value, directions) {
            if (typeof directions !== 'undefined') {
                directions = directions.replace(/ /g, '').split(',');

                $.each(directions, function (index, direction) {
                    setIsScrollAllowed(value, direction, 'm');
                });
            }
            else if (value) {
                FP.setMouseWheelScrolling(true);
                addTouchHandler();
            } else {
                FP.setMouseWheelScrolling(false);
                removeTouchHandler();
            }
        };

        /**
         * Adds or remove the possiblity of scrolling through sections by using the keyboard arrow keys
         */
        FP.setKeyboardScrolling = function (value, directions) {
            if (typeof directions !== 'undefined') {
                directions = directions.replace(/ /g, '').split(',');

                $.each(directions, function (index, direction) {
                    setIsScrollAllowed(value, direction, 'k');
                });
            } else {
                options.keyboardScrolling = value;
            }
        };

        /**
         * Moves the page up one section.
         */
        FP.moveSectionUp = function () {
            var prev = $(SECTION_ACTIVE_SEL).prev(SECTION_SEL);

            //looping to the bottom if there's no more sections above
            if (!prev.length && (options.loopTop || options.continuousVertical)) {
                prev = $(SECTION_SEL).last();
            }

            if (prev.length) {
                scrollPage(prev, null, true);
            }
        };

        /**
         * Moves the page down one section.
         */
        FP.moveSectionDown = function () {
            var next = $(SECTION_ACTIVE_SEL).next(SECTION_SEL);

            //looping to the top if there's no more sections below
            if (!next.length &&
                (options.loopBottom || options.continuousVertical)) {
                next = $(SECTION_SEL).first();
            }

            if (next.length) {
                scrollPage(next, null, false);
            }
        };

        /**
         * Moves the page to the given section with no animation.
         * Anchors or index positions can be used as params.
         */
        FP.silentMoveTo = function (sectionAnchor) {
            requestAnimFrame(function () {
                FP.setScrollingSpeed(0, 'internal');
            });

            FP.moveTo(sectionAnchor);

            requestAnimFrame(function () {
                FP.setScrollingSpeed(originals.scrollingSpeed, 'internal');
            });
        };

        /**
         * Moves the page to the given section.
         * Anchors or index positions can be used as params.
         */
        FP.moveTo = function (sectionAnchor) {
            var destiny = getSectionByAnchor(sectionAnchor);

            if (destiny.length > 0) {
                scrollPage(destiny);
            }
        };

        /**
         * When resizing is finished, we adjust the sections sizes and positions
         */
        FP.reBuild = function (resizing) {
            if (container.hasClass(DESTROYED)) {
                return;
            }  //nothing to do if the plugin was destroyed

            isResizing = true;
            requestAnimFrame(function () {
                isResizing = true;
            });

            var windowsWidth = $window.outerWidth();
            windowsHeight = $window.height();  //updating global var

            //text resizing
            if (options.resize) {
                resizeMe(windowsHeight, windowsWidth);
            }

            $(SECTION_SEL).each(function () {
                //adjusting the height of the table-cell for IE and Firefox
                if (options.verticalCentered) {
                    $(this).find(TABLE_CELL_SEL).css('height', getTableHeight($(this)) + 'px');
                }

                $(this).css('height', windowsHeight + 'px');

                //resizing the scrolling divs
                if (options.scrollOverflow) {
                    createSlimScrolling($(this));
                }
            });

            var activeSection = $(SECTION_ACTIVE_SEL);
            var sectionIndex = activeSection.index(SECTION_SEL);

            //isn't it the first section?
            if (sectionIndex) {
                //adjusting the position for the current section
                FP.silentMoveTo(sectionIndex + 1);
            }

            isResizing = false;
            requestAnimFrame(function () {
                isResizing = false;
            });
            $.isFunction(options.afterResize) && resizing && options.afterResize.call(container);
            $.isFunction(options.afterReBuild) && !resizing && options.afterReBuild.call(container);
        };

        /**
         * Turns fullPage.js to normal scrolling mode when the viewport `width` or `height`
         * are smaller than the set limit values.
         */
        FP.setResponsive = function (active) {
            var isResponsive = $body.hasClass(RESPONSIVE);

            if (active) {
                if (!isResponsive) {
                    FP.setAutoScrolling(false, 'internal');
                    FP.setFitToSection(false, 'internal');
                    $body.addClass(RESPONSIVE);
                }
            }
            else if (isResponsive) {
                FP.setAutoScrolling(originals.autoScrolling, 'internal');
                FP.setFitToSection(originals.autoScrolling, 'internal');
                $body.removeClass(RESPONSIVE);
            }
        };

        var isTouchDevice = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
        var isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0) || (navigator.maxTouchPoints));
        var container = $(this);
        var windowsHeight = $window.height();
        var isResizing = false;
        var isWindowFocused = true;
        var lastScrolledDestiny;
        var canScroll = true;
        var scrollings = [];
        var controlPressed;
        var isScrollAllowed = {};
        isScrollAllowed.m = {'up': true, 'down': true, 'left': true, 'right': true};
        isScrollAllowed.k = $.extend(true, {}, isScrollAllowed.m);
        var originals = $.extend(true, {}, options); //deep copy

        //timeouts
        var resizeId;
        var afterSectionLoadsId;
        var scrollId;
        var scrollId2;
        var keydownId;

        if ($(this).length) {
            init();
            bindEvents();
        }

        function init() {
            //if css3 is not supported, it will use jQuery animations
            if (options.css3) {
                options.css3 = support3d();
            }

            options.scrollBar = options.scrollBar || options.hybrid;


            setOptionsFromDOM();

            prepareDom();
            FP.setAllowScrolling(true);

            FP.setAutoScrolling(options.autoScrolling, 'internal');

            responsive();

            $window.on('load', function () {
                scrollToAnchor();
            });
        }

        function bindEvents() {
            $window
            //when scrolling...
                .on('scroll', scrollHandler)

                //detecting any change on the URL to scroll to the given anchor link
                //(a way to detect back history button as we play with the hashes on the URL)
                //.on('hashchange', hashChangeHandler)

                //when opening a new tab (ctrl + t), `control` won't be pressed when comming back.
                .blur(blurHandler)

                //when resizing the site, we adjust the heights of the sections, slimScroll...
                .resize(resizeHandler);

            $document
            //Sliding with arrow keys, both, vertical and horizontal
                .keydown(keydownHandler)

                //to prevent scrolling while zooming
                .keyup(keyUpHandler);

            /**
             * Applying normalScroll elements.
             * Ignoring the scrolls over the specified selectors.
             */
            if (options.normalScrollElements) {
                $document.on('mouseenter', options.normalScrollElements, function (e) {
                    //log('mouseenter');
                    //FP.setMouseWheelScrolling(false);
                    $document.trigger("fullPageNormalScrollElementEnter", {element: e.currentTarget});
                });

                $document.on('mouseleave', options.normalScrollElements, function (e) {
                    //log('mouseleave');
                    //FP.setMouseWheelScrolling(true);
                    $document.trigger("fullPageNormalScrollElementLeave", {element: e.currentTarget});
                });
            }
        }

        /**
         * Setting options from DOM elements if they are not provided.
         */
        function setOptionsFromDOM() {
            //no anchors option? Checking for them in the DOM attributes
            if (!options.anchors.length) {
                options.anchors = $(options.sectionSelector + '[data-anchor]').map(function () {
                    return $(this).data('anchor').toString();
                }).get();
            }

            //no tooltipos option? Checking for them in the DOM attributes
            /*if (!options.navigationTooltips.length) {
             options.navigationTooltips = $(options.sectionSelector + '[data-tooltip]').map(function () {
             return $(this).data('tooltip').toString();
             }).get();
             }*/
        }

        /**
         * Works over the DOM structure to set it up for the current fullpage optionss.
         */
        function prepareDom() {
            container.css({
                'height': '100%',
                'position': 'relative'
            });

            //adding a class to recognize the container internally in the code
            container.addClass(WRAPPER);
            $('html').addClass(ENABLED);

            //due to https://github.com/alvarotrigo/fullPage.js/issues/1502
            windowsHeight = $window.height();

            container.removeClass(DESTROYED); //in case it was destroyed before initilizing it again

            addInternalSelectors();

            //styling the sections
            $(SECTION_SEL).each(function (index) {
                var section = $(this);

                styleSection(section, index);

                if (options.verticalCentered) {
                    addTableClass(section);
                }
            });

            //fixed elements need to be moved out of the plugin container due to problems with CSS3.
            if (options.fixedElements && options.css3) {
                $(options.fixedElements).appendTo($body);
            }

            //vertical centered of the navigation + active bullet
            /*if (options.navigation) {
             addVerticalNavigation();
             }*/

            if (options.scrollOverflow) {
                if (document.readyState === 'complete') {
                    createSlimScrollingHandler();
                }
                //after DOM and images are loaded
                $window.on('load', createSlimScrollingHandler);
            } else {
                afterRenderActions();
            }
        }

        /**
         * Styling vertical sections
         */
        function styleSection(section, index) {
            //if no active section is defined, the 1st one will be the default one
            if (!index && $(SECTION_ACTIVE_SEL).length === 0) {
                section.addClass(ACTIVE);
            }

            section.css('height', windowsHeight + 'px');

            if (options.paddingTop) {
                section.css('padding-top', options.paddingTop);
            }

            if (options.paddingBottom) {
                section.css('padding-bottom', options.paddingBottom);
            }

            if (typeof options.sectionsColor[index] !== 'undefined') {
                section.css('background-color', options.sectionsColor[index]);
            }

            if (typeof options.anchors[index] !== 'undefined') {
                section.attr('data-anchor', options.anchors[index]);
            }
        }

        /**
         * Adds internal classes to be able to provide customizable selectors
         * keeping the link with the style sheet.
         */
        function addInternalSelectors() {
            //adding internal class names to void problem with common ones
            $(options.sectionSelector).each(function () {
                $(this).addClass(SECTION);
            });
        }

        /**
         * Creates the slim scroll scrollbar for the sections inside them.
         */
        function createSlimScrollingHandler() {
            $(SECTION_SEL).each(function () {

                createSlimScrolling($(this));

            });
            afterRenderActions();
        }

        /**
         * Actions and callbacks to fire afterRender
         */
        function afterRenderActions() {
            var section = $(SECTION_ACTIVE_SEL);

            section.addClass(COMPLETELY);

            if (options.scrollOverflowHandler.afterRender) {
                options.scrollOverflowHandler.afterRender(section);
            }
            playMedia(section);

            $.isFunction(options.afterLoad) && options.afterLoad.call(section, section.data('anchor'), (section.index(SECTION_SEL) + 1));
            $.isFunction(options.afterRender) && options.afterRender.call(container);
        }


        var isScrolling = false;
        var lastScroll = 0;

        //when scrolling...
        function scrollHandler() {
            var currentSection;

            if (!options.autoScrolling || options.scrollBar) {
                var currentScroll = $window.scrollTop();
                var scrollDirection = getScrollDirection(currentScroll);
                var visibleSectionIndex = 0;
                var screen_mid = currentScroll + ($window.height() / 2.0);

                //taking the section which is showing more content in the viewport
                var sections = document.querySelectorAll(SECTION_SEL);
                for (var i = 0; i < sections.length; ++i) {
                    var section = sections[i];

                    // Pick the the last section which passes the middle line of the screen.
                    if (section.offsetTop <= screen_mid) {
                        visibleSectionIndex = i;
                    }
                }

                if (isCompletelyInViewPort(scrollDirection)) {
                    if (!$(SECTION_ACTIVE_SEL).hasClass(COMPLETELY)) {
                        $(SECTION_ACTIVE_SEL).addClass(COMPLETELY).siblings().removeClass(COMPLETELY);
                    }
                }

                //geting the last one, the current one on the screen
                currentSection = $(sections).eq(visibleSectionIndex);

                //setting the visible section as active when manually scrolling
                //executing only once the first time we reach the section
                if (!currentSection.hasClass(ACTIVE)) {
                    isScrolling = true;
                    var leavingSection = $(SECTION_ACTIVE_SEL);
                    var leavingSectionIndex = leavingSection.index(SECTION_SEL) + 1;
                    var yMovement = getYmovement(currentSection);
                    var anchorLink = currentSection.data('anchor');
                    var sectionIndex = currentSection.index(SECTION_SEL) + 1;

                    if (canScroll) {
                        currentSection.addClass(ACTIVE).siblings().removeClass(ACTIVE);

                        $.isFunction(options.onLeave) && options.onLeave.call(leavingSection, leavingSectionIndex, sectionIndex, yMovement);

                        $.isFunction(options.afterLoad) && options.afterLoad.call(currentSection, anchorLink, sectionIndex);

                        if (options.anchors.length) {
                            //needed to enter in hashChange event when using the menu with anchor links
                            lastScrolledDestiny = anchorLink;

                            //setState(anchorLink, sectionIndex);
                        }
                    }

                    //small timeout in order to avoid entering in hashChange event when scrolling is not finished yet
                    clearTimeout(scrollId);
                    scrollId = setTimeout(function () {
                        isScrolling = false;
                    }, 100);
                }

                if (options.fitToSection) {
                    //for the auto adjust of the viewport to fit a whole section
                    clearTimeout(scrollId2);

                    scrollId2 = setTimeout(function () {
                        //checking fitToSection again in case it was set to false before the timeout delay
                        if (canScroll && options.fitToSection) {
                            //allows to scroll to an active section and
                            //if the section is already active, we prevent firing callbacks
                            if ($(SECTION_ACTIVE_SEL).is(currentSection)) {
                                requestAnimFrame(function () {
                                    isResizing = true;
                                });
                            }
                            scrollPage($(SECTION_ACTIVE_SEL));

                            requestAnimFrame(function () {
                                isResizing = false;
                            });
                        }
                    }, options.fitToSectionDelay);
                }
            }
        }

        /**
         * Determines whether the active section has seen in its whole or not.
         */
        function isCompletelyInViewPort(movement) {
            var top = $(SECTION_ACTIVE_SEL).position().top;
            var bottom = top + $window.height();

            if (movement == 'up') {
                return bottom >= ($window.scrollTop() + $window.height());
            }
            return top <= $window.scrollTop();
        }

        /**
         * Gets the directon of the the scrolling fired by the scroll event.
         */
        function getScrollDirection(currentScroll) {
            var direction = currentScroll > lastScroll ? 'down' : 'up';

            lastScroll = currentScroll;

            return direction;
        }

        /**
         * Determines the way of scrolling up or down:
         * by 'automatically' scrolling a section or by using the default and normal scrolling.
         */
        /*function scrolling(type, scrollable) {
            if (!isScrollAllowed.m[type]) {
                return;
            }
            var check, scrollSection;

            if (type == 'down') {
                check = 'bottom';
                scrollSection = FP.moveSectionDown;
            } else {
                check = 'top';
                scrollSection = FP.moveSectionUp;
            }

            if (scrollable.length > 0) {
                //is the scrollbar at the start/end of the scroll?
                if (options.scrollOverflowHandler.isScrolled(check, scrollable)) {
                    scrollSection();
                } else {
                    return true;
                }
            } else {
                // moved up/down
                scrollSection();
            }
        }*/


        var touchStartY = 0;
        var touchStartX = 0;
        var touchEndY = 0;
        var touchEndX = 0;

        /* Detecting touch events

         * As we are changing the top property of the page on scrolling, we can not use the traditional way to detect it.
         * This way, the touchstart and the touch moves shows an small difference between them which is the
         * used one to determine the direction.
         */
        function touchMoveHandler(event) {
            var e = event.originalEvent;

            // additional: if one of the normalScrollElements isn't within options.normalScrollElementTouchThreshold hops up the DOM chain
            if (!checkParentForNormalScrollElement(event.target) && isReallyTouch(e)) {

                if (options.autoScrolling) {
                    //preventing the easing on iOS devices
                    event.preventDefault();
                }

                var activeSection = $(SECTION_ACTIVE_SEL);
                var scrollable = options.scrollOverflowHandler.scrollable(activeSection);

                if (canScroll) { //if theres any #
                    var touchEvents = getEventsPage(e);

                    touchEndY = touchEvents.y;
                    touchEndX = touchEvents.x;

                    if (options.autoScrolling) {

                        //is the movement greater than the minimum resistance to scroll?
                        if (Math.abs(touchStartY - touchEndY) > ($window.height() / 100 * options.touchSensitivity)) {
                            if (touchStartY > touchEndY) {
                                // ligne modifiée ligne13
                                $document.trigger("fullPageTouchMove", {code: 'down'});
                                // ligne originale fullPage.js
                                //scrolling('down', scrollable);
                            } else if (touchEndY > touchStartY) {
                                // ligne modifiée ligne13
                                $document.trigger("fullPageTouchMove", {code: 'up'});
                                // ligne originale fullPage.js
                                //scrolling('up', scrollable);
                            }
                        }
                    }
                }
            }

        }

        /**
         * recursive function to loop up the parent nodes to check if one of them exists in options.normalScrollElements
         * Currently works well for iOS - Android might need some testing
         * @param  {Element} el  target element / jquery selector (in subsequent nodes)
         * @param  {int}     hop current hop compared to options.normalScrollElementTouchThreshold
         * @return {boolean} true if there is a match to options.normalScrollElements
         */
        function checkParentForNormalScrollElement(el, hop) {
            hop = hop || 0;
            var parent = $(el).parent();

            if (hop < options.normalScrollElementTouchThreshold &&
                parent.is(options.normalScrollElements)) {
                return true;
            } else if (hop == options.normalScrollElementTouchThreshold) {
                return false;
            } else {
                return checkParentForNormalScrollElement(parent, ++hop);
            }
        }

        /**
         * As IE >= 10 fires both touch and mouse events when using a mouse in a touchscreen
         * this way we make sure that is really a touch event what IE is detecting.
         */
        function isReallyTouch(e) {
            //if is not IE   ||  IE is detecting `touch` or `pen`
            return typeof e.pointerType === 'undefined' || e.pointerType != 'mouse';
        }

        /**
         * Handler for the touch start event.
         */
        function touchStartHandler(event) {
            var e = event.originalEvent;

            //stopping the auto scroll to adjust to a section
            if (options.fitToSection) {
                $htmlBody.stop();
            }

            if (isReallyTouch(e)) {
                var touchEvents = getEventsPage(e);
                touchStartY = touchEvents.y;
                touchStartX = touchEvents.x;
            }
        }

        /**
         * Gets the average of the last `number` elements of the given array.
         */
        function getAverage(elements, number) {
            var sum = 0;

            //taking `number` elements from the end to make the average, if there are not enought, 1
            var lastElements = elements.slice(Math.max(elements.length - number, 1));

            for (var i = 0; i < lastElements.length; i++) {
                sum = sum + lastElements[i];
            }

            return Math.ceil(sum / number);
        }

        /**
         * Detecting mousewheel scrolling
         *
         * http://blogs.sitepointstatic.com/examples/tech/mouse-wheel/index.html
         * http://www.sitepoint.com/html5-javascript-mouse-wheel/
         */
        var prevTime = new Date().getTime();

        function MouseWheelHandler(e) {
            var curTime = new Date().getTime();
            var isNormalScroll = $(COMPLETELY_SEL).hasClass(NORMAL_SCROLL);

            //autoscrolling and not zooming?
            if (options.autoScrolling && !controlPressed && !isNormalScroll) {
                // cross-browser wheel delta
                e = e || window.event;
                var value = e.wheelDelta || -e.deltaY || -e.detail;
                var delta = Math.max(-1, Math.min(1, value));

                var horizontalDetection = typeof e.wheelDeltaX !== 'undefined' || typeof e.deltaX !== 'undefined';
                var isScrollingVertically = (Math.abs(e.wheelDeltaX) < Math.abs(e.wheelDelta)) || (Math.abs(e.deltaX) < Math.abs(e.deltaY) || !horizontalDetection);

                //Limiting the array to 150 (lets not waste memory!)
                if (scrollings.length > 149) {
                    scrollings.shift();
                }

                //keeping record of the previous scrollings
                scrollings.push(Math.abs(value));

                //preventing to scroll the site on mouse wheel when scrollbar is present
                if (options.scrollBar) {
                    e.preventDefault ? e.preventDefault() : e.returnValue = false;
                }

                var activeSection = $(SECTION_ACTIVE_SEL);
                var scrollable = options.scrollOverflowHandler.scrollable(activeSection);

                //time difference between the last scroll and the current one
                var timeDiff = curTime - prevTime;
                prevTime = curTime;

                //haven't they scrolled in a while?
                //(enough to be consider a different scrolling action to scroll another section)
                if (timeDiff > 200) {
                    //emptying the array, we dont care about old scrollings for our averages
                    scrollings = [];
                }

                if (canScroll) {
                    var averageEnd = getAverage(scrollings, 10);
                    var averageMiddle = getAverage(scrollings, 70);
                    var isAccelerating = averageEnd >= averageMiddle;

                    //to avoid double swipes...
                    if (isAccelerating && isScrollingVertically) {
                        //scrolling down?
                        if (delta < 0) {
                            // ligne modifiée ligne13
                            $document.trigger("fullPageMouseWheelScroll", {code: 'down'});
                            // ligne originale FullPage.js
                            //scrolling('down', scrollable);

                            //scrolling up?
                        } else {
                            // ligne modifiée ligne13
                            $document.trigger("fullPageMouseWheelScroll", {code: 'up'});
                            // ligne originale FullPage.js
                            //scrolling('up', scrollable);
                        }
                    }
                }

                return false;
            }

            if (options.fitToSection) {
                //stopping the auto scroll to adjust to a section
                $htmlBody.stop();
            }
        }

        //IE < 10 pollify for requestAnimationFrame
        window.requestAnimFrame = function () {
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (callback) {
                    callback()
                }
        }();

        var previousDestTop = 0;

        /**
         * Returns the destination Y position based on the scrolling direction and
         * the height of the section.
         */
        function getDestinationPosition(dest, element) {

            //top of the desination will be at the top of the viewport
            var position = dest.top;
            var isScrollingDown = dest.top > previousDestTop;
            var sectionBottom = position - windowsHeight + element.outerHeight();

            //is the destination element bigger than the viewport?
            if (element.outerHeight() > windowsHeight) {
                //scrolling up?
                if (!isScrollingDown) {
                    position = sectionBottom;
                }
            }

            //sections equal or smaller than the viewport height AND scrolling down?
            else if (isScrollingDown) {
                //The bottom of the destination will be at the bottom of the viewport
                position = sectionBottom;
            }

            /*
             Keeping record of the last scrolled position to determine the scrolling direction.
             No conventional methods can be used as the scroll bar might not be present
             AND the section might not be active if it is auto-height and didnt reach the middle
             of the viewport.
             */
            previousDestTop = position;
            return position;
        }

        /**
         * Scrolls the site to the given element.
         */
        function scrollPage(element, callback, isMovementUp) {
            //requestAnimFrame is used in order to prevent a Chrome 44 bug (http://stackoverflow.com/a/31961816/1081396)
            requestAnimFrame(function () {
                var dest = element.position();
                if (typeof dest === 'undefined') {
                    return;
                } //there's no element to scroll, leaving the function

                var dtop = getDestinationPosition(dest, element);

                //local variables
                var v = {
                    element: element,
                    callback: callback,
                    isMovementUp: isMovementUp,
                    dest: dest,
                    dtop: dtop,
                    yMovement: getYmovement(element),
                    anchorLink: element.data('anchor'),
                    sectionIndex: element.index(SECTION_SEL),
                    activeSection: $(SECTION_ACTIVE_SEL),
                    leavingSection: $(SECTION_ACTIVE_SEL).index(SECTION_SEL) + 1,

                    //caching the value of isResizing at the momment the function is called
                    //because it will be checked later inside a setTimeout and the value might change
                    localIsResizing: isResizing
                };

                //quiting when destination scroll is the same as the current one
                if ((v.activeSection.is(element) && !isResizing) || (options.scrollBar && $window.scrollTop() === v.dtop && !element.hasClass(AUTO_HEIGHT) )) {
                    return;
                }

                // If continuousVertical && we need to wrap around
                if (options.autoScrolling && options.continuousVertical && typeof (v.isMovementUp) !== "undefined" &&
                    ((!v.isMovementUp && v.yMovement == 'up') || // Intending to scroll down but about to go up or
                    (v.isMovementUp && v.yMovement == 'down'))) { // intending to scroll up but about to go down

                    v = createInfiniteSections(v);
                }

                //callback (onLeave) if the site is not just resizing
                if ($.isFunction(options.onLeave) && !v.localIsResizing) {
                    if (options.onLeave.call(v.activeSection, v.leavingSection, (v.sectionIndex + 1), v.yMovement) === false) {
                        return;
                    }
                }
                stopMedia(v.activeSection);

                element.addClass(ACTIVE).siblings().removeClass(ACTIVE);

                //preventing from activating the MouseWheelHandler event
                //more than once if the page is scrolling
                canScroll = false;

                //setState(v.anchorLink, v.sectionIndex);

                performMovement(v);

                //flag to avoid callingn `scrollPage()` twice in case of using anchor links
                lastScrolledDestiny = v.anchorLink;
            });
        }

        /**
         * Performs the movement (by CSS3 or by jQuery)
         */
        function performMovement(v) {
            // using CSS3 translate functionality
            if (options.css3 && options.autoScrolling && !options.scrollBar) {
                var translate3d = 'translate3d(0px, -' + v.dtop + 'px, 0px)';
                transformContainer(translate3d, true);

                //even when the scrollingSpeed is 0 there's a little delay, which might cause the
                //scrollingSpeed to change in case of using silentMoveTo();
                if (options.scrollingSpeed) {
                    afterSectionLoadsId = setTimeout(function () {
                        afterSectionLoads(v);
                    }, options.scrollingSpeed);
                } else {
                    afterSectionLoads(v);
                }
            }

            // using jQuery animate
            else {
                var scrollSettings = getScrollSettings(v);

                $(scrollSettings.element).animate(
                    scrollSettings.options,
                    options.scrollingSpeed, options.easing).promise().done(function () { //only one single callback in case of animating  `html, body`
                    if (options.scrollBar) {

                        /* Hack!
                         The timeout prevents setting the most dominant section in the viewport as "active" when the user
                         scrolled to a smaller section by using the mousewheel (auto scrolling) rather than draging the scroll bar.

                         When using scrollBar:true It seems like the scroll events still getting propagated even after the scrolling animation has finished.
                         */
                        setTimeout(function () {
                            afterSectionLoads(v);
                        }, 30);
                    } else {
                        afterSectionLoads(v);
                    }
                });
            }
        }

        /**
         * Gets the scrolling settings depending on the plugin autoScrolling option
         */
        function getScrollSettings(v) {
            var scroll = {};

            if (options.autoScrolling && !options.scrollBar) {
                scroll.options = {'top': -v.dtop};
                scroll.element = WRAPPER_SEL;
            } else {
                scroll.options = {'scrollTop': v.dtop};
                scroll.element = 'html, body';
            }

            return scroll;
        }

        /**
         * Adds sections before or after the current one to create the infinite effect.
         */
        function createInfiniteSections(v) {
            // Scrolling down
            if (!v.isMovementUp) {
                // Move all previous sections to after the active section
                $(SECTION_ACTIVE_SEL).after(v.activeSection.prevAll(SECTION_SEL).get().reverse());
            }
            else { // Scrolling up
                // Move all next sections to before the active section
                $(SECTION_ACTIVE_SEL).before(v.activeSection.nextAll(SECTION_SEL));
            }

            // Maintain the displayed position (now that we changed the element order)
            silentScroll($(SECTION_ACTIVE_SEL).position().top);

            // save for later the elements that still need to be reordered
            v.wrapAroundElements = v.activeSection;

            // Recalculate animation variables
            v.dest = v.element.position();
            v.dtop = v.dest.top;
            v.yMovement = getYmovement(v.element);

            return v;
        }

        /**
         * Fix section order after continuousVertical changes have been animated
         */
        function continuousVerticalFixSectionOrder(v) {
            // If continuousVertical is in effect (and autoScrolling would also be in effect then),
            // finish moving the elements around so the direct navigation will function more simply
            if (!v.wrapAroundElements || !v.wrapAroundElements.length) {
                return;
            }

            if (v.isMovementUp) {
                $(SECTION_FIRST_SEL).before(v.wrapAroundElements);
            }
            else {
                $(SECTION_LAST_SEL).after(v.wrapAroundElements);
            }

            silentScroll($(SECTION_ACTIVE_SEL).position().top);
        }


        /**
         * Actions to do once the section is loaded.
         */
        function afterSectionLoads(v) {
            continuousVerticalFixSectionOrder(v);

            v.element.find(SCROLLABLE_SEL).mouseover();

            //callback (afterLoad) if the site is not just resizing
            $.isFunction(options.afterLoad) && !v.localIsResizing && options.afterLoad.call(v.element, v.anchorLink, (v.sectionIndex + 1));

            playMedia(v.element);
            v.element.addClass(COMPLETELY).siblings().removeClass(COMPLETELY);

            canScroll = true;

            $.isFunction(v.callback) && v.callback.call(this);
        }

        /**
         * Plays video and audio elements.
         */
        function playMedia(destiny) {

            //playing HTML5 media elements
            destiny.find('video, audio').each(function () {
                var element = $(this).get(0);

                if (element.hasAttribute('autoplay') && typeof element.play === 'function') {
                    element.play();
                }
            });
        }

        /**
         * Stops video and audio elements.
         */
        function stopMedia(destiny) {

            //stopping HTML5 media elements
            destiny.find('video, audio').each(function () {
                var element = $(this).get(0);

                if (!element.hasAttribute('data-ignore') && typeof element.pause === 'function') {
                    element.pause();
                }
            });
        }

        /**
         * Scrolls to the anchor in the URL when loading the site
         */
        function scrollToAnchor() {
            //getting the anchor link in the URL and deleting the `#`
            /*var value = window.location.hash.replace('#', '').split('/');
             var section = value[0];
             var slide = value[1];*/
            var value = window.location.pathname.substring(1).split("/");
            var section = value[0];

            if (section) {  //if theres any #
                if (options.animateAnchor) {
                    scrollPageByAnchor(section);
                } else {
                    FP.silentMoveTo(section);
                }
            }
        }

        //Sliding with arrow keys, both, vertical and horizontal
        function keydownHandler(e) {

            clearTimeout(keydownId);

            var activeElement = $(':focus');

            if (!activeElement.is('textarea') && !activeElement.is('input') && !activeElement.is('select') &&
                options.keyboardScrolling && options.autoScrolling) {
                var keyCode = e.which;

                //preventing the scroll with arrow keys & spacebar & Page Up & Down keys
                var keyControls = [40, 38, 32, 33, 34];
                if ($.inArray(keyCode, keyControls) > -1) {
                    e.preventDefault();
                }

                controlPressed = e.ctrlKey;

                keydownId = setTimeout(function () {
                    onkeydown(e);
                }, 150);
            }
        }

        /*function tooltipTextHandler() {
         $(this).prev().trigger('click');
         }*/

        //to prevent scrolling while zooming
        function keyUpHandler(e) {
            if (isWindowFocused) { //the keyup gets fired on new tab ctrl + t in Firefox
                controlPressed = e.ctrlKey;
            }
        }

        //binding the mousemove when the mouse's middle button is released
        function mouseDownHandler(e) {
            //middle button
            if (e.which == 2) {
                oldPageY = e.pageY;
                container.on('mousemove', mouseMoveHandler);
            }
        }

        //unbinding the mousemove when the mouse's middle button is released
        function mouseUpHandler(e) {
            //middle button
            if (e.which == 2) {
                container.off('mousemove');
            }
        }

        //when opening a new tab (ctrl + t), `control` won't be pressed when comming back.
        function blurHandler() {
            isWindowFocused = false;
            controlPressed = false;
        }

        //Scrolls to the section when clicking the navigation bullet
        /*function sectionBulletHandler(e) {
         e.preventDefault();
         var index = $(this).parent().index();
         scrollPage($(SECTION_SEL).eq(index));
         }*/

        /**
         * Keydown event
         */
        function onkeydown(e) {
            var shiftPressed = e.shiftKey;

            switch (e.which) {
                //up
                case 38:
                case 33:
                    if (isScrollAllowed.k.up) {
                        // ligne modifiée ligne13
                        $document.trigger('fullPageKeydown', {code: 'up'});
                        // ligne originale FullPage.js
                        //FP.moveSectionUp();
                    }
                    break;

                //down
                case 32: //spacebar
                    if (shiftPressed && isScrollAllowed.k.up) {
                        // ligne modifiée ligne13
                        $document.trigger('fullPageKeydown', {code: 'up'});
                        // ligne originale FullPage.js
                        //FP.moveSectionUp();
                        break;
                    }
                case 40:
                case 34:
                    if (isScrollAllowed.k.down) {
                        // ligne modifiée ligne13
                        $document.trigger('fullPageKeydown', {code: 'down'});
                        // ligne originale FullPage.js
                        //FP.moveSectionDown();
                    }
                    break;

                //Home
                case 36:
                    if (isScrollAllowed.k.up) {
                        // ligne modifiée ligne13
                        $document.trigger('fullPageKeydown', {code: 'home'});
                        // ligne originale FullPage.js
                        //FP.moveTo(1);
                    }
                    break;

                //End
                case 35:
                    if (isScrollAllowed.k.down) {
                        // ligne modifiée ligne13
                        $document.trigger('fullPageKeydown', {code: 'end'});
                        // ligne originale FullPage.js
                        //FP.moveTo( $(SECTION_SEL).length );
                    }
                    break;

                //left
                /*case 37:
                 if (isScrollAllowed.k.left) {
                 FP.moveSlideLeft();
                 }
                 break;

                 //right
                 case 39:
                 if (isScrollAllowed.k.right) {
                 FP.moveSlideRight();
                 }
                 break;*/

                default:
                    return; // exit this handler for other keys
            }
        }

        /**
         * Detecting the direction of the mouse movement.
         * Used only for the middle button of the mouse.
         */
        var oldPageY = 0;

        function mouseMoveHandler(e) {
            if (canScroll) {
                // moving up
                if (e.pageY < oldPageY && isScrollAllowed.m.up) {
                    FP.moveSectionUp();
                }

                // moving down
                else if (e.pageY > oldPageY && isScrollAllowed.m.down) {
                    FP.moveSectionDown();
                }
            }
            oldPageY = e.pageY;
        }

        var previousHeight = windowsHeight;

        //when resizing the site, we adjust the heights of the sections, slimScroll...
        function resizeHandler() {
            //checking if it needs to get responsive
            responsive();

            // rebuild immediately on touch devices
            if (isTouchDevice) {
                var activeElement = $(document.activeElement);

                //if the keyboard is NOT visible
                if (!activeElement.is('textarea') && !activeElement.is('input') && !activeElement.is('select')) {
                    var currentHeight = $window.height();

                    //making sure the change in the viewport size is enough to force a rebuild. (20 % of the window to avoid problems when hidding scroll bars)
                    if (Math.abs(currentHeight - previousHeight) > (20 * Math.max(previousHeight, currentHeight) / 100)) {
                        FP.reBuild(true);
                        previousHeight = currentHeight;
                    }
                }
            } else {
                //in order to call the functions only when the resize is finished
                //http://stackoverflow.com/questions/4298612/jquery-how-to-call-resize-event-only-once-its-finished-resizing
                clearTimeout(resizeId);

                resizeId = setTimeout(function () {
                    FP.reBuild(true);
                }, 350);
            }
        }

        /**
         * Checks if the site needs to get responsive and disables autoScrolling if so.
         * A class `fp-responsive` is added to the plugin's container in case the user wants to use it for his own responsive CSS.
         */
        function responsive() {
            var widthLimit = options.responsive || options.responsiveWidth; //backwards compatiblity
            var heightLimit = options.responsiveHeight;

            //only calculating what we need. Remember its called on the resize event.
            var isBreakingPointWidth = widthLimit && $window.outerWidth() < widthLimit;
            var isBreakingPointHeight = heightLimit && $window.height() < heightLimit;

            if (widthLimit && heightLimit) {
                FP.setResponsive(isBreakingPointWidth || isBreakingPointHeight);
            }
            else if (widthLimit) {
                FP.setResponsive(isBreakingPointWidth);
            }
            else if (heightLimit) {
                FP.setResponsive(isBreakingPointHeight);
            }
        }

        /**
         * Adds transition animations for the given element
         */
        function addAnimation(element) {
            var transition = 'all ' + options.scrollingSpeed + 'ms ' + options.easingcss3;

            element.removeClass(NO_TRANSITION);
            return element.css({
                '-webkit-transition': transition,
                'transition': transition
            });
        }

        /**
         * Remove transition animations for the given element
         */
        function removeAnimation(element) {
            return element.addClass(NO_TRANSITION);
        }

        /**
         * Resizing of the font size depending on the window size as well as some of the images on the site.
         */
        function resizeMe(displayHeight, displayWidth) {
            //Standard dimensions, for which the body font size is correct
            var preferredHeight = 825;
            var preferredWidth = 900;

            if (displayHeight < preferredHeight || displayWidth < preferredWidth) {
                var heightPercentage = (displayHeight * 100) / preferredHeight;
                var widthPercentage = (displayWidth * 100) / preferredWidth;
                var percentage = Math.min(heightPercentage, widthPercentage);
                var newFontSize = percentage.toFixed(2);

                $body.css('font-size', newFontSize + '%');
            } else {
                $body.css('font-size', '100%');
            }
        }

        /**
         * Retuns `up` or `down` depending on the scrolling movement to reach its destination
         * from the current section.
         */
        function getYmovement(destiny) {
            var fromIndex = $(SECTION_ACTIVE_SEL).index(SECTION_SEL);
            var toIndex = destiny.index(SECTION_SEL);
            if (fromIndex == toIndex) {
                return 'none';
            }
            if (fromIndex > toIndex) {
                return 'up';
            }
            return 'down';
        }

        function createSlimScrolling(element) {
            //needed to make `scrollHeight` work under Opera 12
            element.css('overflow', 'hidden');

            var scrollOverflowHandler = options.scrollOverflowHandler;
            var wrap = scrollOverflowHandler.wrapContent();
            //in case element is a slide
            var section = element.closest(SECTION_SEL);
            var scrollable = scrollOverflowHandler.scrollable(element);
            var contentHeight;

            //if there was scroll, the contentHeight will be the one in the scrollable section
            if (scrollable.length) {
                contentHeight = scrollOverflowHandler.scrollHeight(element);
            } else {
                contentHeight = element.get(0).scrollHeight;
                if (options.verticalCentered) {
                    contentHeight = element.find(TABLE_CELL_SEL).get(0).scrollHeight;
                }
            }

            var scrollHeight = windowsHeight - parseInt(section.css('padding-bottom')) - parseInt(section.css('padding-top'));

            //needs scroll?
            if (contentHeight > scrollHeight) {
                //was there already an scroll ? Updating it
                if (scrollable.length) {
                    scrollOverflowHandler.update(element, scrollHeight);
                }
                //creating the scrolling
                else {
                    if (options.verticalCentered) {
                        element.find(TABLE_CELL_SEL).wrapInner(wrap);
                    } else {
                        element.wrapInner(wrap);
                    }
                    scrollOverflowHandler.create(element, scrollHeight);
                }
            }
            //removing the scrolling when it is not necessary anymore
            else {
                scrollOverflowHandler.remove(element);
            }

            //undo
            element.css('overflow', '');
        }

        function addTableClass(element) {
            element.addClass(TABLE).wrapInner('<div class="' + TABLE_CELL + '" style="height:' + getTableHeight(element) + 'px;" />');
        }

        function getTableHeight(element) {
            var sectionHeight = windowsHeight;

            if (options.paddingTop || options.paddingBottom) {
                var section = element;
                if (!section.hasClass(SECTION)) {
                    section = element.closest(SECTION_SEL);
                }

                var paddings = parseInt(section.css('padding-top')) + parseInt(section.css('padding-bottom'));
                sectionHeight = (windowsHeight - paddings);
            }

            return sectionHeight;
        }

        /**
         * Adds a css3 transform property to the container class with or without animation depending on the animated param.
         */
        function transformContainer(translate3d, animated) {
            if (animated) {
                addAnimation(container);
            } else {
                removeAnimation(container);
            }

            container.css(getTransforms(translate3d));

            //syncronously removing the class after the animation has been applied.
            setTimeout(function () {
                container.removeClass(NO_TRANSITION);
            }, 10);
        }

        /**
         * Gets a section by its anchor / index
         */
        function getSectionByAnchor(sectionAnchor) {
            //section
            var section = container.find(SECTION_SEL + '[data-anchor="' + sectionAnchor + '"]');
            if (!section.length) {
                section = $(SECTION_SEL).eq((sectionAnchor - 1));
            }

            return section;
        }

        /**
         * Scrolls to the given section anchor
         */
        function scrollPageByAnchor(destiny) {
            var section = getSectionByAnchor(destiny);

            //we need to scroll to the section
            if (destiny !== lastScrolledDestiny && !section.hasClass(ACTIVE)) {
                scrollPage(section);
            }
        }

        /**
         * Gets the anchor for the given section. Its index will be used if there's none.
         */
        function getAnchor(element) {
            var anchor = element.data('anchor');
            var index = element.index();

            //Slide without anchor link? We take the index instead.
            if (typeof anchor === 'undefined') {
                anchor = index;
            }

            return anchor;
        }

        /**
         * Checks for translate3d support
         * @return boolean
         * http://stackoverflow.com/questions/5661671/detecting-transform-translate3d-support
         */
        function support3d() {
            var el = document.createElement('p'),
                has3d,
                transforms = {
                    'webkitTransform': '-webkit-transform',
                    'OTransform': '-o-transform',
                    'msTransform': '-ms-transform',
                    'MozTransform': '-moz-transform',
                    'transform': 'transform'
                };

            // Add it to the body to get the computed style.
            document.body.insertBefore(el, null);

            for (var t in transforms) {
                if (el.style[t] !== undefined) {
                    el.style[t] = 'translate3d(1px,1px,1px)';
                    has3d = window.getComputedStyle(el).getPropertyValue(transforms[t]);
                }
            }

            document.body.removeChild(el);

            return (has3d !== undefined && has3d.length > 0 && has3d !== 'none');
        }

        /**
         * Removes the auto scrolling action fired by the mouse wheel and trackpad.
         * After this function is called, the mousewheel and trackpad movements won't scroll through sections.
         */
        function removeMouseWheelHandler() {
            if (document.addEventListener) {
                document.removeEventListener('mousewheel', MouseWheelHandler, false); //IE9, Chrome, Safari, Oper
                document.removeEventListener('wheel', MouseWheelHandler, false); //Firefox
                document.removeEventListener('MozMousePixelScroll', MouseWheelHandler, false); //old Firefox
            } else {
                document.detachEvent('onmousewheel', MouseWheelHandler); //IE 6/7/8
            }
        }

        /**
         * Adds the auto scrolling action for the mouse wheel and trackpad.
         * After this function is called, the mousewheel and trackpad movements will scroll through sections
         * https://developer.mozilla.org/en-US/docs/Web/Events/wheel
         */
        function addMouseWheelHandler() {
            var prefix = '';
            var _addEventListener;

            if (window.addEventListener) {
                _addEventListener = "addEventListener";
            } else {
                _addEventListener = "attachEvent";
                prefix = 'on';
            }

            // detect available wheel event
            var support = 'onwheel' in document.createElement('div') ? 'wheel' : // Modern browsers support "wheel"
                document.onmousewheel !== undefined ? 'mousewheel' : // Webkit and IE support at least "mousewheel"
                    'DOMMouseScroll'; // let's assume that remaining browsers are older Firefox


            if (support == 'DOMMouseScroll') {
                document[_addEventListener](prefix + 'MozMousePixelScroll', MouseWheelHandler, false);
            }

            //handle MozMousePixelScroll in older Firefox
            else {
                document[_addEventListener](prefix + support, MouseWheelHandler, false);
            }
        }

        /**
         * Binding the mousemove when the mouse's middle button is pressed
         */
        function addMiddleWheelHandler() {
            container
                .on('mousedown', mouseDownHandler)
                .on('mouseup', mouseUpHandler);
        }

        /**
         * Unbinding the mousemove when the mouse's middle button is released
         */
        function removeMiddleWheelHandler() {
            container
                .off('mousedown', mouseDownHandler)
                .off('mouseup', mouseUpHandler);
        }

        /**
         * Adds the possibility to auto scroll through sections on touch devices.
         */
        function addTouchHandler() {
            if (isTouchDevice || isTouch) {
                //Microsoft pointers
                var MSPointer = getMSPointer();

                $(WRAPPER_SEL).off('touchstart ' + MSPointer.down).on('touchstart ' + MSPointer.down, touchStartHandler);
                $(WRAPPER_SEL).off('touchmove ' + MSPointer.move).on('touchmove ' + MSPointer.move, touchMoveHandler);
            }
        }

        /**
         * Removes the auto scrolling for touch devices.
         */
        function removeTouchHandler() {
            if (isTouchDevice || isTouch) {
                //Microsoft pointers
                var MSPointer = getMSPointer();

                $(WRAPPER_SEL).off('touchstart ' + MSPointer.down);
                $(WRAPPER_SEL).off('touchmove ' + MSPointer.move);
            }
        }

        /*
         * Returns and object with Microsoft pointers (for IE<11 and for IE >= 11)
         * http://msdn.microsoft.com/en-us/library/ie/dn304886(v=vs.85).aspx
         */
        function getMSPointer() {
            var pointer;

            //IE >= 11 & rest of browsers
            if (window.PointerEvent) {
                pointer = {down: 'pointerdown', move: 'pointermove'};
            }

            //IE < 11
            else {
                pointer = {down: 'MSPointerDown', move: 'MSPointerMove'};
            }

            return pointer;
        }

        /**
         * Gets the pageX and pageY properties depending on the browser.
         * https://github.com/alvarotrigo/fullPage.js/issues/194#issuecomment-34069854
         */
        function getEventsPage(e) {
            var events = [];

            events.y = (typeof e.pageY !== 'undefined' && (e.pageY || e.pageX) ? e.pageY : e.touches[0].pageY);
            events.x = (typeof e.pageX !== 'undefined' && (e.pageY || e.pageX) ? e.pageX : e.touches[0].pageX);

            //in touch devices with scrollBar:true, e.pageY is detected, but we have to deal with touch events. #1008
            if (isTouch && isReallyTouch(e) && options.scrollBar) {
                events.y = e.touches[0].pageY;
                events.x = e.touches[0].pageX;
            }

            return events;
        }

        /**
         * Scrolls silently (with no animation) the page to the given Y position.
         */
        function silentScroll(top) {
            if (options.scrollBar) {
                container.scrollTop(top);
            }
            else if (options.css3) {
                var translate3d = 'translate3d(0px, -' + top + 'px, 0px)';
                transformContainer(translate3d, false);
            }
            else {
                container.css('top', -top);
            }
        }

        /**
         * Returns the cross-browser transform string.
         */
        function getTransforms(translate3d) {
            return {
                '-webkit-transform': translate3d,
                '-moz-transform': translate3d,
                '-ms-transform': translate3d,
                'transform': translate3d
            };
        }

        /**
         * Allowing or disallowing the mouse/swipe scroll in a given direction. (not for keyboard)
         * @type  m (mouse) or k (keyboard)
         */
        function setIsScrollAllowed(value, direction, type) {
            switch (direction) {
                case 'up':
                    isScrollAllowed[type].up = value;
                    break;
                case 'down':
                    isScrollAllowed[type].down = value;
                    break;
                case 'left':
                    isScrollAllowed[type].left = value;
                    break;
                case 'right':
                    isScrollAllowed[type].right = value;
                    break;
                case 'all':
                    if (type == 'm') {
                        FP.setAllowScrolling(value);
                    } else {
                        FP.setKeyboardScrolling(value);
                    }
            }
        }

        /*
         * Sets the state for a variable with multiple states (original, and temporal)
         * Some variables such as `autoScrolling` or `recordHistory` might change automatically its state when using `responsive` or `autoScrolling:false`.
         * This function is used to keep track of both states, the original and the temporal one.
         * If type is not 'internal', then we assume the user is globally changing the variable.
         */
        function setVariableState(variable, value, type) {
            options[variable] = value;
            if (type !== 'internal') {
                originals[variable] = value;
            }
        }

        /**
         * Displays warnings
         */
        function displayWarnings() {
            if ($('html').hasClass(ENABLED)) {
                showError('error', 'Fullpage.js can only be initialized once and you are doing it multiple times!');
                return;
            }

            // Disable mutually exclusive settings
            if (options.continuousVertical &&
                (options.loopTop || options.loopBottom)) {
                options.continuousVertical = false;
                showError('warn', 'Option `loopTop/loopBottom` is mutually exclusive with `continuousVertical`; `continuousVertical` disabled');
            }

            if (options.scrollBar && options.scrollOverflow) {
                showError('warn', 'Option `scrollBar` is mutually exclusive with `scrollOverflow`. Sections with scrollOverflow might not work well in Firefox');
            }

            if (options.continuousVertical && options.scrollBar) {
                options.continuousVertical = false;
                showError('warn', 'Option `scrollBar` is mutually exclusive with `continuousVertical`; `continuousVertical` disabled');
            }

            //anchors can not have the same value as any element ID or NAME
            $.each(options.anchors, function (index, name) {

                //case insensitive selectors (http://stackoverflow.com/a/19465187/1081396)
                var nameAttr = $document.find('[name]').filter(function () {
                    return $(this).attr('name') && $(this).attr('name').toLowerCase() == name.toLowerCase();
                });

                var idAttr = $document.find('[id]').filter(function () {
                    return this.id && this.id.toLowerCase() == name.toLowerCase();
                });

                if (idAttr.length || nameAttr.length) {
                    showError('error', 'data-anchor tags can not have the same value as any `id` element on the site (or `name` element for IE).');
                    idAttr.length && showError('error', '"' + name + '" is is being used by another element `id` property');
                    nameAttr.length && showError('error', '"' + name + '" is is being used by another element `name` property');
                }
            });
        }

        /**
         * Shows a message in the console of the given type.
         */
        function showError(type, text) {
            console && console[type] && console[type]('fullPage: ' + text);
        }
    };

    /**
     * An object to handle overflow scrolling.
     * This uses jquery.slimScroll to accomplish overflow scrolling.
     * It is possible to pass in an alternate scrollOverflowHandler
     * to the fullpage.js option that implements the same functions
     * as this handler.
     *
     * @type {Object}
     */
    var slimScrollHandler = {
        /**
         * Optional function called after each render.
         *
         * Solves a bug with slimScroll vendor library #1037, #553
         *
         * @param  {object} section jQuery object containing rendered section
         */
        afterRender: function (section) {
            var scrollableWrap = section.find(SCROLLABLE_SEL);

            scrollableWrap.mouseover();
        },

        /**
         * Called when overflow scrolling is needed for a section.
         *
         * @param  {Object} element      jQuery object containing current section
         * @param  {Number} scrollHeight Current window height in pixels
         */
        create: function (element, scrollHeight) {
            element.find(SCROLLABLE_SEL).slimScroll({
                allowPageScroll: true,
                height: scrollHeight + 'px',
                size: '10px',
                alwaysVisible: true
            });
        },

        /**
         * Return a boolean depending on whether the scrollable element is a
         * the end or at the start of the scrolling depending on the given type.
         *
         * @param  {String}  type       Either 'top' or 'bottom'
         * @param  {Object}  scrollable jQuery object for the scrollable element
         * @return {Boolean}
         */
        isScrolled: function (type, scrollable) {
            if (type === 'top') {
                return !scrollable.scrollTop();
            } else if (type === 'bottom') {
                return scrollable.scrollTop() + 1 + scrollable.innerHeight() >= scrollable[0].scrollHeight;
            }
        },

        /**
         * Returns the scrollable element for the given section.
         *
         * @param  {Object}  activeSection jQuery object containing current section
         * @return {Boolean}
         */
        scrollable: function (activeSection) {
            return activeSection.find(SCROLLABLE_SEL);
        },

        /**
         * Returns the scroll height of the wrapped content.
         * If this is larger than the window height minus section padding,
         * overflow scrolling is needed.
         *
         * @param  {Object} element jQuery object containing current section
         * @return {Number}
         */
        scrollHeight: function (element) {
            return element.find(SCROLLABLE_SEL).get(0).scrollHeight;
        },

        /**
         * Called when overflow scrolling is no longer needed for a section.
         *
         * @param  {Object} element      jQuery object containing current section
         */
        remove: function (element) {
            element.find(SCROLLABLE_SEL).children().first().unwrap().unwrap();
            element.find(SLIMSCROLL_BAR_SEL).remove();
            element.find(SLIMSCROLL_RAIL_SEL).remove();
        },

        /**
         * Called when overflow scrolling has already been setup but the
         * window height has potentially changed.
         *
         * @param  {Object} element      jQuery object containing current section
         * @param  {Number} scrollHeight Current window height in pixels
         */
        update: function (element, scrollHeight) {
            element.find(SCROLLABLE_SEL).css('height', scrollHeight + 'px').parent().css('height', scrollHeight + 'px');
        },

        /**
         * Called to get any additional elements needed to wrap the section
         * content in order to facilitate overflow scrolling.
         *
         * @return {String|Object} Can be a string containing HTML,
         *                         a DOM element, or jQuery object.
         */
        wrapContent: function () {
            return '<div class="' + SCROLLABLE + '"></div>';
        }
    };

    defaultScrollHandler = slimScrollHandler;

});
