/**
 * BackgroundSlider jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'BackgroundSlider',
        defaults = {
            responsiveWidth: 768,
            app: null,
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this._current = -1;
        this._slides;
        this._numSlides;
        this._paused = false;
        this._nextTimeout;
        this._enabled = true;
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            self._slides = self.$element.find('.slide');
            self._numSlides = self._slides.length;
            self.next();
        },
        preloadSlide: function (index, callback) {
            var self = this;
            var $slide = $(self._slides[index]);
            var attr;
            if (self.settings.app.md.mobile()) {
                attr = "data-background-image-mobile";
            } else {
                attr = "data-background-image";
            }
            $('<img/>').attr('src', $slide.attr(attr)).load(function () {
                $(this).remove();
                $slide.css('backgroundImage', 'url(' + $slide.attr(attr) + ')');
                self.$element.trigger('imageLoaded');
                if (callback) {
                    callback();
                }
            });
        },
        next: function () {
            var self = this;
            var nextIndex = (self._current < self._numSlides - 1) ? self._current + 1 : 0;
            var $nextSlide = $(self._slides[nextIndex]);
            if ($nextSlide.css('background-image') == 'none') {
                // load the image before process slide
                self.preloadSlide(nextIndex, function () {
                    self.next();
                });
            } else {
                //log('next');
                TweenLite.set($nextSlide, {
                    autoAlpha: 0
                });
                self.$element.find('.slide.active').removeClass('active');
                $nextSlide.show();
                $nextSlide.addClass('active KenBurns');
                TweenLite.to($nextSlide, 1, {
                    autoAlpha: 1,
                    onComplete: function () {
                        self.$element.find('.slide:not(.active)').hide().removeClass('KenBurns');
                    }
                });
                self._current = nextIndex;
                if ($(window).width() < self.settings.responsiveWidth) {
                    self._enabled = false;
                } else {
                    // le slideshow n'est activé que sur desktop (>=768)
                    self._nextTimeout = setTimeout(function () {
                        if (!self._paused) {
                            self.next();
                        }
                    }, 7000);
                }
            }
        },
        pause: function () {
            //log('pause');
            var self = this;
            clearTimeout(self._nextTimeout);
            self._paused = true;
            self.$element.addClass('paused');
        },
        resume: function () {
            //log('resume');
            var self = this;
            if (self._paused && self._enabled) {
                self._paused = false;
                self.$element.removeClass('paused');
                self.next();
            }
        },
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);