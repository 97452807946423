/**
 * ContactPane jQuery plugin
 */
(function ($, window, document, undefined) {
    var pluginName = 'ContactPane',
        defaults = {
            app: null,
            tweenSpeed: 0.5,
            tweenDelay: 0.5,
            // this prop stores the route state of the app just before the pane opens, used to go back to that route when pane closes
            routeOnClose: "/",
            maxTopCSSPositionWhenOpen: 60, // topBar height
        };

    function Plugin(element, options) {
        this.element = element;
        this.$element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this._opened = false;
        this.$paneHeader = this.$element.find('.pane-header');
        this.$paneBody = this.$element.find('.pane-body');
        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            var self = this;
            self.resize();
            self.iOSSafari();
            self.$element.find('form').attr('action', '/contact');
            self.$paneBody.mCustomScrollbar({
                axis: 'y',
            });
            //self.debug();

        },
        debug: function () {
            var self = this;
            self.$paneHeader.find('a').on('click', function (a) {
                log('DEBUG ContactPane $paneHeader click');
            });
        },
        iOSSafari: function () {
            var self = this;
            if (self.settings.app.md.is('iOS') && self.settings.app.md.is('Safari')) {
                $(window).on('scroll', function (e) {
                    if (!self.isOpen()) {
                        var top = window.innerHeight - self.$paneHeader.outerHeight() + 1;
                        self.$element.css('top', top + 'px');
                    }
                });
            }
        },
        resize: function () {
            var self = this;
            if (self.isOpen()) {
                var top = self._topCSSPositionWhenOpen();
                self.$element.css('top', top + 'px');
                if (top <= self.settings.maxTopCSSPositionWhenOpen) {
                    self.$paneBody.height(windowHeight() - top - self.$paneHeader.outerHeight() - (self.$paneBody.css('padding-top').replace(/[^-\d\.]/g, '') * 2 - 1));
                } else {
                    self.$paneBody.height('auto');
                    var top = self._topCSSPositionWhenOpen();
                    self.$element.css('top', top + 'px');
                }
            } else {
                self.$element.css('top', self._topCSSPositionWhenClosed() + 'px');
            }
        },
        setRouteOnClose: function (value) {
            var self = this;
            self.settings.routeOnClose = value;
            self.$element.data('routeOnClose', value);
            self.$element.find('.btn-close').attr('href', value);
            // on modifie le href du bouton d'ouverture du pane pour qu'il se comporte comme le bouton fermer lorsque le pane est ouvert
            self.$paneHeader.find('a').attr('href', value);
        },
        open: function () {
            var self = this;

            // set gclid hidden field value (in GravityForms this field has ID #11)
            $('input#input_1_11').val(readCookie('gclid'));
            $('input#input_3_11').val(readCookie('gclid'));

            if (self.isOpen() === false) {
                self.$element.trigger('contactPaneOpen');
                self.$element.addClass('opening');
                self._opened = true;
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    top: self._topCSSPositionWhenOpen() + 'px',
                    onComplete: function () {
                        self.resize();
                        self.$element.removeClass('opening').addClass('open');
                        self.$element.trigger('contactPaneOpened');
                    }
                });
            }
        },
        close: function () {
            var self = this;
            if (self.isOpen()) {
                self.$element.trigger('contactPaneClose');
                self.$element.removeClass('opening').removeClass('open').addClass('closing');
                self._opened = false;
                // on reset le href du bouton d'ouverture
                self.$paneHeader.find('a').attr('href', '/contact');
                TweenLite.to(self.$element, self.settings.tweenSpeed, {
                    top: self._topCSSPositionWhenClosed() + 'px',
                    onComplete: function () {
                        self.$element.removeClass('closing');
                        self.$element.trigger('contactPaneClosed');
                    }
                });
            }
        },
        toggle: function () {
            return this.isOpen() ? this.close() : this.open();
        },
        isOpen: function () {
            return this._opened;
        },
        _topCSSPositionWhenClosed: function () {
            var self = this;
            return windowHeight() - self.$paneHeader.outerHeight() + 1;
        },
        _topCSSPositionWhenOpen: function () {
            var self = this;
            return Math.max(self.settings.maxTopCSSPositionWhenOpen, windowHeight() - self.$element.height());
        },
    });

    $.fn[pluginName] = function (options, params) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            } else if ($.isFunction(Plugin.prototype[options])) {
                return $.data(this, "plugin_" + pluginName)[options](params);
            }
        });
    };
})(jQuery, window, document);
